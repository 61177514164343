<template>
  <div ref="newServiceModal">
    <b-tab active class="border-0">
      <template v-slot:title>
        <span class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block">
          Autre préstations
        </span>
      </template>
      
      <v-table :data="data" class="table">
        <thead slot="head">
          <th>Prestation</th>
          <th>Désignation</th>
          <th>Unité de mesure</th>
          <th>Type de contrôl</th>
          <th>Prix unitaire</th>
          <th>Qté. Prévision</th>
          <th>Type Prévision</th>
          <th>Qté. Annuelle</th>
          <th>Qté. Contrat</th>
          <th>Actions</th>
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <tr v-for="row in displayData" :key="row.id">
            <td>{{ row.service.name }}</td>
            <td>{{ row.name }}</td>
            <td>
              <span class="badge badge-primary">{{ row.uom }}</span>
            </td>
            <td>
              <span class="badge badge-info">{{ row.control_type.name }}</span>
            </td>
            <td>{{ row.unit_price.withCurrency }}</td>
            <td>{{ row.estimated_qty.formated }}</td>
            <td>
              <span class="badge badge-info">{{
                row.estimation_type.name
              }}</span>
            </td>
            <td>{{ row.yearly_qty.formated }}</td>
            <td>{{ row.contractual_qty.formated }}</td>
            <td>
              <a href="javascript:void(0)" v-if="showValidationBtn" @click="editSingleService(row.id)"
                ><i class="fas fa-edit"></i
              ></a>
              <a
                href="javascript:void(0)" v-if="showValidationBtn"
                @click="deleteServiceFromContract(row.name, row.id)"
                ><i class="fas fa-trash text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </v-table>
      <div class="row">
        <div class="col-md-12">
          <h5>
            <a
              v-if="showValidationBtn"
              @click.prevent="addNewRestServiceClick"
              href="javascript:void(0)"
              class=""
              ><i class="fas fa-plus-circle"></i> Ajouter</a
            >
          </h5>
        </div>
      </div>
    </b-tab>

    <Modal
      v-model="showNewRestaurationService"
      title="Ajouter une prestation"
      ref="newRestaurationServiceModal"
    >
      <form @submit.prevent="saveNewCustomServiceToContract">
        <div class="row">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Prestation *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newServiceModal.formData.prestation"
                    track-by="id"
                    :select-label="''"
                    label="name"
                    :options="newServiceModal.prestationsList"
                    placeholder="Select Prestation"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.prestation.$error,
                    }"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted && $v.newServiceModal.formData.prestation.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.prestation.required"
                      >Champ obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-8">
                  <div class="form-group">
                  <label for="formrow-email-input">Désignation *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.name"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted && $v.newServiceModal.formData.name.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div
                    v-if="submitted && $v.newServiceModal.formData.name.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.name.required"
                      >Champ obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Unité de mesure *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newServiceModal.formData.Uom"
                    track-by="id"
                    :select-label="''"
                    label="designation"
                    :options="newServiceModal.UomsList"
                    placeholder="Select Unité de mesure"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted && $v.newServiceModal.formData.Uom.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div
                    v-if="submitted && $v.newServiceModal.formData.Uom.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.Uom.required"
                      >Champ obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type contrôle *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newServiceModal.formData.contractControlType"
                    track-by="id"
                    :select-label="''"
                    label="name"
                    :options="newServiceModal.contractControlTypesList"
                    placeholder="Select Type contrôle"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.contractControlType.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newServiceModal.formData.contractControlType.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newServiceModal.formData.contractControlType
                          .required
                      "
                      >Champ obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type prévision *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newServiceModal.formData.contractEstimationType"
                    track-by="id"
                    :select-label="''"
                    label="name"
                    :options="newServiceModal.contractEstimationTypesList"
                    placeholder="Select Type prévision"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.contractEstimationType
                          .$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newServiceModal.formData.contractEstimationType
                          .$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newServiceModal.formData.contractEstimationType
                          .required
                      "
                      >Champ obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Prix Unitaire *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.unitPrice"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.unitPrice.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div v-if="submitted && $v.newServiceModal.formData.unitPrice.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.unitPrice.required">Champ obligatoire.</span>
                    <span v-if="!$v.newServiceModal.formData.unitPrice.decimal">Champ Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté. Prévision *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.estimatedQty"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.estimatedQty.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newServiceModal.formData.estimatedQty.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.estimatedQty.required">Champ obligatoire.</span>
                    <span v-if="!$v.newServiceModal.formData.estimatedQty.decimal">Champ Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté. Annuelle *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.annualQty"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.annualQty.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div
                    v-if="
                      submitted && $v.newServiceModal.formData.annualQty.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.annualQty.required">Champ obligatoire.</span>
                    <span v-if="!$v.newServiceModal.formData.annualQty.decimal">Champ Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté. Contrat *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.contractualQty"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.contractualQty.$error,
                    }"
                    class="form-control"
                    name=""
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newServiceModal.formData.contractualQty.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.contractualQty.required">Champ obligatoire.</span>
                    <span v-if="!$v.newServiceModal.formData.contractualQty.decimal">Champ Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté Min *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.minQty"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.minQty.$error,
                    }"
                    class="form-control"
                    name=""
                    id=""
                  />
                  <div v-if="submitted && $v.newServiceModal.formData.minQty.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.minQty.required">Champ obligatoire.</span>
                    <span v-if="!$v.newServiceModal.formData.minQty.decimal">Champ Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Unité d'oeuvre *</label>
                  <input
                    type="text"
                    v-model="newServiceModal.formData.woUnit"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newServiceModal.formData.woUnit.$error,
                    }"
                    class="form-control"
                    name=""
                    id=""
                  />
                  <div
                    v-if="submitted && $v.newServiceModal.formData.woUnit.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newServiceModal.formData.woUnit.required">Champ obligatoire.</span>
                    <span v-if="!$v.newServiceModal.formData.woUnit.decimal">Champ Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <button class="btn btn-primary" type="submit">
            <i class="fas fa-save"></i>
            Enregistrer
          </button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import { getDropDownElementsByTypeApi , fetchProductsCategoriesListApi, fetchProductsWithCategoryListApi} from "@/api/common";
import { required,requiredIf, decimal } from "vuelidate/lib/validators";
export default {
  components: { Multiselect },
  import: { getDropDownElementsByTypeApi, fetchProductsCategoriesListApi, fetchProductsWithCategoryListApi },
  props: {
    contract: {},
    camp: {},
    service: {},
  },
  computed: {
    showValidationBtn(){
      var show = true;
      if(this.contract){
        show = this.contract.statusPlain == 'draft';
      }
      return show;
    },
    formDisabled(){
      var disabled = false;
      if(this.contract){
        disabled = this.contract.statusPlain != 'draft';
      }
      return disabled;
    },
  },
  data() {
    return {
      isModalLoading: false,
      data: [],
      submitted: false,
      newServiceModal: {
        prestationsList: [],
        UomsList: [],
        contractControlTypesList: [],
        contractEstimationTypesList: [],
        formData: {
          name: "",
          prestation: "",
          Uom: "",
          contractControlType: "",
          contractEstimationType: "",
          annualQty: "",
          contractualQty: "",
          unitPrice: "",
          estimatedQty: "",
          minQty: "",
          woUnit: "",
          service: "",
        },
      },
      showNewRestaurationService: false,
    };
  },

  watch: {
    contract() {
      
    },

    camp() {
      
    },

    showNewRestaurationService() {
      if (this.showNewRestaurationService) {
        this.loadNewServiceModalData();
      }
    },
  },

  mounted() {
    this.reloadContractServices();
  },

  methods: {
    reloadContractServices() {
      var campId = this.camp.id;
      var contractId = this.contract.id;
      var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/getCustomContractServicesInCamp", {
          campId: campId,
          contractId: contractId,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var services = res.data.customServices;
          switch (status) {
            case 200:
              this.data = services;
              
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    addNewRestServiceClick() {
      this.showNewRestaurationService = true;
      // this.loadNewServiceModalData();
    },

    loadProductsViaCategory(){
      fetchProductsWithCategoryListApi(this.newServiceModal.formData.productCategId.id)
      .then((res) => {
        this.newServiceModal.productsList = res.data.products;
      })
      .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    loadNewServiceModalData() {
      var loader = this.$loading.show();
      var res = this.fetchBaseData();
      this.fetchProductsCategories();
      loader.hide();
    },
    fetchProductsCategories(){
      fetchProductsCategoriesListApi()
      .then((res) => {
        this.newServiceModal.productCategsList = res.data.data;
      })
      .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    fetchBaseData() {
        var campId = this.camp.id;
        var contractId = this.contract.id;
        var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/fetchBaseData", {
            campId: campId,
            contractId: contractId,
        })
        .then((res) => {
            loader.hide();
          this.newServiceModal.mealsTypesList = res.data.mealTypes;
          this.newServiceModal.UomsList = res.data.uoms;
          this.newServiceModal.prestationsList = res.data.customServicesList;
          this.newServiceModal.mealsServicesList = res.data.mealServices;
          this.newServiceModal.mealsLocationsList = res.data.mealLocations;
          this.newServiceModal.mealsPeriodsList = res.data.mealPeriods;
          this.newServiceModal.contractControlTypesList = res.data.controlTypes;
          this.newServiceModal.contractEstimationTypesList =
            res.data.estimationTypes;
        })
        .catch(() => {
            loader.hide();

          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    

    saveNewCustomServiceToContract() {
      // send data to api
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.newServiceModal.formData.service = this.service;
        this.newServiceModal.formData.contract = this.contract;
        this.newServiceModal.formData.camp = this.camp;
        var loader = this.$loading.show();
        this.$http
          .post(
            "/contracts/contracts/saveCustomServiceUnitPriceData",
            this.newServiceModal.formData
          )
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.showNewRestaurationService = false;
                this.reloadContractServices();
                this.$emit('reloadContractServicesStatus', true);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
      // if (true) {

      // }
    },

    editSingleService(servId) {
      // get service details
      var loader = this.$loading.show({ zIndex: 1700 });
      this.$http
        .post("/contracts/contracts/getSingleCustomServiceDetails", {
          servId: servId,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var service = res.data.service;
          switch (status) {
            case 200:
              // this.$toast.success(res.data.original.msg);
              this.newServiceModal.formData.prestation = service.service;
              this.newServiceModal.formData.name = service.name;
              this.newServiceModal.formData.annualQty = service.yearly_qty.plain;
              this.newServiceModal.formData.contractualQty = service.contractual_qty.plain;
              this.newServiceModal.formData.unitPrice = service.unit_price.plain;
              this.newServiceModal.formData.estimatedQty = service.estimated_qty.plain;

              this.newServiceModal.formData.minQty = service.minQty.plain;
              this.newServiceModal.formData.woUnit = service.woUnit.plain;
              
              this.newServiceModal.formData.Uom = service.uomObj;

              this.newServiceModal.formData.contractControlType = service.control_type;
              this.newServiceModal.formData.contractEstimationType = service.estimation_type;

              this.newServiceModal.formData.isUpdate = true;
              this.newServiceModal.formData.servId = service.id;

              this.showNewRestaurationService = true;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    deleteServiceFromContract(servName, servId) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer Le service: '" + servName + "' ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show({ zIndex: 1700 });
          this.$http
            .post("/contracts/contracts/deleteCustomServiceFromContract", {
              servId: servId,
              contract: this.contract.id,
              camp: this.camp.id,
              type: "catering",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit('reloadContractServicesStatus', true);
                  this.reloadContractServices();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },

  validations: {
    newServiceModal: {
      formData: {
        name: {required},
        annualQty: { required, decimal },
        contractualQty: { required, decimal },
        unitPrice: { required, decimal },
        estimatedQty: { required, decimal },
        prestation: { required },
        Uom: { required },
        contractControlType: { required },
        contractEstimationType: { required },
        minQty: { required, decimal },
        woUnit: { required, decimal },
      },
    },
  },
};
</script>
