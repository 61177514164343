<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import contactsTable from "./components/contactsTable_beta";
import appConfig from "@/app.config";
import globalContractInfos from "./components/globalContractInfos";
import contractualScope from "./components/ContractualScopeBeta";
import FinancialScope from "./components/financialScope.vue";
import UnitPriceSchedule from "./components/UnitPriceScheduleBeta.vue";
import ContractSynoptic from "./components/ContractSynoptic.vue";
import ContractPenalties from "./components/ContractPenalties.vue";
import notificationsAndAlerts from "./components/notificationsAndAlerts.vue";
import ContractReview from "./components/ContractReview.vue";
import SideButtons from '../../../../../../components/side-buttons.vue';
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import { fetchLifebasesApi, fetchContractStdServicesApi } from "@/api/common";
import { getDropDownElementsByTypeApi } from "@/api/common";
import { isAdmin } from "@/helpers/functions";
export default {
  page: {
    title: "Contrats",
    meta: [{ name: "description", content: appConfig.description }],
  },
  import: {
    fetchLifebasesApi,
    fetchContractStdServicesApi,
    getDropDownElementsByTypeApi,
    isAdmin
  },

  components: {
    Layout,
    PageHeader,
    contactsTable,
    globalContractInfos,
    contractualScope,
    FinancialScope,
    notificationsAndAlerts,
    UnitPriceSchedule,
    ContractSynoptic,
    ContractPenalties,
    ContractReview,
    SideButtons,
    Multiselect
  },
  data() {
    return {
      disableForm : true,
      disableAddBtn: false,
      resetAddBtn: false,
      suppliers: [],
      lifebases: [],
      stdServices: [],
      usedLifebases: [],
      contractTypes: [],
      usedServices: [],
      penaltiesTypes: [],
      fullPageLoading: true,
      formStep: 0,
      currentSelectedContract: null,
      generalInfosSetUp: false,
      shouldRefreshContractsTable: false,
      scopeSetUp: false,
      financeInfosSetUp: false,
      priceListInfosSetUp: false,
      synopticsInfosSetUp: false,
      notificationsInfosSetup: false,
      penaltiesInfosSetup: false,
      contractApproved: false,
      active: true,
      selectedContractSevricesChangedData: [],
      selectedContractCustomSevricesChangedData: [],
      shouldClearAllForms: false,
      selectedCampsData: [],
      title: "Contrats",
      newAmandmentContractModal: {
        show: false,
        type: "",
        isClosingAmnd: false,
        copy: {
          contractualScope: false,
          financeScope: false,
          priceScope: false,
          notificationsAndAlerts: false,
          synoptics: false,
        },
        
      },
      newAmandmentContractModalTypes: [
          {
            id: ' ',
            name: '',
          },
          {
            id: 'without_financial_impact ',
            name: 'Avenant sans impact financier',
          },
          {
            id: 'with_financial_impact ',
            name: 'Avenant avec impact financier',
          }
        ],
      items: [
        {
          text: "Master Data",
          to: { name: 'master_data' }
        },
        {
          text: "Fiche Contrat",
          to: { name : 'base.contracts.contracts.index' }
        },
        {
          text: "Gestion des contrats",
          active: true,
        },
      ],
    };
  },

  watch: {
    selectedContractSevricesChangedData() {},

    selectedCampsData() {
    },

    shouldClearAllForms(){
      if(this.shouldClearAllForms){
          this.generalInfosSetUp = false;
          this.scopeSetUp = false;
          this.financeInfosSetUp = false;
          this.priceListInfosSetUp = false;
          this.synopticsInfosSetUp = false;
          this.penaltiesInfosSetup = false;
          this.notificationsInfosSetup = false;
          this.contractApproved = false;
          this.disableAddBtn = false;
      }
    }
  },

  mounted() {
    // this.fetchSuppliersList();
    this.fetchBaseData();
    // this.fetchLifebasesList();
  },

  methods: {
    generateAmendmantContract(){
      let loader = this.$loading.show();
        this.$http
        .post("/contracts/contracts/generateAmendmantContract", {
          data: this.newAmandmentContractModal,
        })
        .then((res) => {
          var status = res.data.original.status;
          loader.hide();
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.newAmandmentContractModal.show = false;
              this.newAmandmentContractModal.contractId = null;
              this.newAmandmentContractModal.copy.contractualScope = false;
              this.newAmandmentContractModal.copy.financeScope = false;
              this.newAmandmentContractModal.copy.priceScope = false;
              this.newAmandmentContractModal.copy.notificationsAndAlerts = false;
              this.newAmandmentContractModal.copy.synoptics = false;
              this.shouldRefreshContractsTable = true;
              this.newAmandmentContractModal.newAmandmentContractModal.type = "";
              this.currentSelectedContract = null;
              // reload table
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    fetchBaseData() {
      this.$http
        .post("/contracts/contracts/fetchBaseData")
        .then((res) => {
          this.contractTypes = res.data.contractTypes;
          this.stdServices = res.data.stdServices;
          this.penaltiesTypes = res.data.penaltiesTypes;
          if(res.data.camps.length > 0){
            this.lifebases = res.data.camps;
          }
          this.suppliers = res.data.suppliers;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    fetchContractDetails(uuid = null) {
      let loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/getSingleContractData/" + uuid)
        .then((res) => {
          var status = res.data.status;
          var contract = res.data.data.contract;
          loader.hide();
          switch (status) {
            case 200:
              this.currentSelectedContract = contract;
              this.shouldClearAllForms = false;
              this.updateTabsUi();
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadContractDataToFormComponent(selectedContract) {
      if (selectedContract) {
        this.disableAddBtn = false;
        this.fetchContractDetails(selectedContract.uuid);
      }
      
    },

    handleReloadPenaltiesTableEvent(){
      this.fetchContractDetails(this.currentSelectedContract.uuid);
    },

    updateTabsUi() {
      this.generalInfosSetUp = Boolean(
        this.currentSelectedContract.general_infos_setup
      );
      this.scopeSetUp = Boolean(this.currentSelectedContract.scope_setup);
      this.financeInfosSetUp = Boolean(
        this.currentSelectedContract.financial_infos_setup
      );
      this.priceListInfosSetUp = Boolean(
        this.currentSelectedContract.pricelist_infos_setup
      );
      this.penaltiesInfosSetup = Boolean(
        this.currentSelectedContract.penalties_infos_setup
      );
      this.synopticsInfosSetUp = Boolean(
        this.currentSelectedContract.synptics_infos_setup
      );
      this.notificationsInfosSetup = Boolean(
        this.currentSelectedContract.notifications_infos_setup
      );
      this.contractApproved = Boolean(
        this.currentSelectedContract.statusPlain == "approved"
      );
    },

    handleGeneralInfoStepSavedEvent(contract) {
      this.currentSelectedContract = contract;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshContractsTable = true;
      // jump to next tab
      this.formStep = 1;
    },

    handleContractualScopeSavedEvent() {
      this.fetchContractDetails(this.currentSelectedContract.uuid);
      this.scopeSetUp = true;
      // refresh contracts list
      this.shouldRefreshContractsTable = true;
      // jump to next tab
      this.formStep = 2;
    },

    handleFinancialInfoStepSavedEvent(contract) {
      this.financeInfosSetUp = true;
      this.fetchContractDetails(this.currentSelectedContract.uuid);
      // refresh contracts list
      this.shouldRefreshContractsTable = true;
      // jump to next tab
      this.formStep = 3;
    },
    handleUnitPriceScheduleDoneEvent() {
      this.fetchContractDetails(this.currentSelectedContract.uuid);
      this.priceListInfosSetUp = true;
      this.formStep = 4;
    },
    handleSynopticInfoStepSavedEvent() {
      this.fetchContractDetails(this.currentSelectedContract.uuid);
      this.synopticsInfosSetUp = true;
      // jump to next tab
    },
    handleNotificationsInfoStepSavedEvent() {
      this.fetchContractDetails(this.currentSelectedContract.uuid);
      this.notificationsInfosSetup = true;
      this.formStep = 5;
    },
    handleContractApprovedEvent() {
      this.fetchContractDetails(this.currentSelectedContract.uuid);
      this.shouldRefreshContractsTable = true;
      this.contractApproved = true;
    },
    handleClearAllContractFormsEvent(){
      this.handleCancelCurrentFormEvent();
    },

    handleReloadContractServicesStatusEvent(){
      this.fetchContractDetails(this.currentSelectedContract.uuid);
    },

    handleCancelCurrentFormEvent(){
      this.currentSelectedContract = null;
      this.disableForm = true;
      this.shouldClearAllForms = true;
      this.resetAddBtn = true;
      this.formStep = 0;
    },

    deleteSelectedContract(event){
      if(event){
        if(this.currentSelectedContract){
          if(this.currentSelectedContract.statusPlain == 'draft'){
            // if have permission
            if(this.$can('delete_contract')) {
              Swal.fire({
                title: "Êtes-vous sûr de supprimer le contrat: " + this.currentSelectedContract.ref + "?",
                text: "Vous ne pourrez pas annuler cela!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Supprimer!",
              }).then((result) => {
                if (result.value) {
                  var loader = this.$loading.show();
                  this.$http
                    .post("/contracts/contracts/delete/" + this.currentSelectedContract.uuid)
                    .then((res) => {
                      var status = res.data.original.status;
                      loader.hide();
                      switch (status) {
                        case 200:
                          this.$toast.success(res.data.original.msg);
                          this.currentSelectedContract = null;
                          this.shouldRefreshContractsTable = true;
                          this.shouldClearAllForms = true;
                          break;
        
                        case 500:
                          this.$toast.warning(res.data.original.msg);
                          break;
                      }
                    })
                    .catch((error) => {
                      loader.hide();
                      this.$toast.error(error.message);
                    })
                    .finally(() => {});
                }
              });
            } else {
              this.$toast.error("Vous n'avez pas l'autorisation.");
            }
          }else{
            this.$toast.error("Impossible de supprimer le contrat N°: <strong>"+ this.currentSelectedContract.ref +"</strong>");
          }
        }else{
          this.$toast.error("Veuillez selectionner un contrat");
        }
      }
    },

    addAmandmentClick(){
      this.newAmandmentContractModal.contractId = this.currentSelectedContract.uuid;
      this.newAmandmentContractModal.show = true;
    },

    openEditSelectedContract(event){
      
      if(event){
        if(this.currentSelectedContract){
          if(this.currentSelectedContract.statusPlain == 'draft'){
            // if have permission
            if(this.$can('edit_contract')) {
              this.disableForm = false;
            } else{
              // dont have permission to edit contract
              this.$toast.error("Vous n'avez pas l'autorisation.");
            }
          }else{
            if(!isAdmin()){
              this.$toast.error("Impossible de modifer le contrat N°: <strong>"+ this.currentSelectedContract.ref +"</strong>");
            } else {
              this.disableForm = false;
            }
          }
        }else{
          this.$toast.error("Veuillez selectionner un contrat");
        }
      }
      
    },

    addNewContract(event){
      if(event){
        this.resetAddBtn = false;
        this.disableForm = false;
        this.currentSelectedContract = null;
        this.shouldClearAllForms = true;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- contracts header with table and search -->
    <SideButtons 
    :reset="resetAddBtn"
    :select="disableAddBtn"
    :save="items" 
    :contract="currentSelectedContract" 
    @info ="items" 
    @add="addNewContract($event)" 
    @save="items" 
    @edit="openEditSelectedContract($event)" 
    @confirm="items" 
    @validate="items" 
    @delete="deleteSelectedContract($event)"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button
                block
                @click="active = !active"
                style="background:#e5e9f0; color:black !important; border:none !important;"
              >
                <div class="row">
                  <div class="col text-left mt-1">
                    <i class="mdi mdi-format-list-bulleted"></i> Liste des
                    Contrats
                  </div>
                  <div class="col text-right">
                    <i
                      v-if="active"
                      class="mdi mdi-chevron-up font-size-18"
                    ></i>
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i>
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse
              id="accordion-1"
              :visible="active"
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body class="shadow-lg">
                <div class="table-responsive mb-0 shadow">
                  <contacts-table
                    @loadContractDataToFormComponent="handleLoadContractDataToFormComponent"
                    @clearAllContractForms="handleClearAllContractFormsEvent"
                    :refreshTableEvent="shouldRefreshContractsTable"
                  />
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">
              Fiche contrat
              <span v-if="currentSelectedContract != null" class="text-primary">( Contrat N°: {{ currentSelectedContract.ref }} )</span>
              <template v-if="currentSelectedContract != null">
                <button v-if="currentSelectedContract.isParentContract && currentSelectedContract.statusPlain == 'approved'"  class="btn btn-success" @click="addAmandmentClick"> <i class="fas fa-plus"></i> Ajouter un avenant</button>
              </template>
              
               <!-- v-if="currentSelectedContract != null" -->
            </h4>
            <p class="card-title-desc">
              Utiliser cette partie pour identifier, modifier, ou consulter un
              contrat.
            </p>
            <b-tabs content-class="p-3 text-muted" v-model="formStep">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Infos Générales
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="generalInfosSetUp"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>
                  </span>
                </template>

                <global-contract-infos
                  @GeneralInfoStepSaved="handleGeneralInfoStepSavedEvent"
                  @cancelCurrentForm="handleCancelCurrentFormEvent"
                  :contract="currentSelectedContract"
                  :contractTypes="contractTypes"
                  :suppliers="suppliers"
                  :formDisabled="disableForm"
                  :shouldClearAllForms="shouldClearAllForms"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-user"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Périmètre Contractuel
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="scopeSetUp"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>
                  </span>
                </template>
                <contractual-scope
                  @selectedContractSevricesChanged="selectedContractSevricesChangedData = $event"
                  @cancelCurrentForm="handleCancelCurrentFormEvent"
                  @selectedContractCustomSevricesChanged="selectedContractCustomSevricesChangedData = $event"
                  @campBaseSelected="selectedCampsData = $event"
                  @ContractualScopeSaved="handleContractualScopeSavedEvent"
                  :contract="currentSelectedContract"
                  :lifebases="lifebases"
                  :services="stdServices"
                  :formDisabled="disableForm"
                  :shouldClearAllForms="shouldClearAllForms"
                />
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Aspects Financiers
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="financeInfosSetUp"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>
                  </span>
                </template>
                <financial-scope
                  :contract="currentSelectedContract"
                  :contractualScope="selectedContractSevricesChangedData"
                  :customContractualScope="selectedContractCustomSevricesChangedData"
                  :services="stdServices"
                  :selectedCamps="selectedCampsData"
                  @FinancialInfoStepSaved="handleFinancialInfoStepSavedEvent"
                  @cancelCurrentForm="handleCancelCurrentFormEvent"
                  :formDisabled="disableForm"
                  :shouldClearAllForms="shouldClearAllForms"
                />
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Bordereau des prix
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="priceListInfosSetUp"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>
                  </span>
                </template>
                <unit-price-schedule
                  :contract="currentSelectedContract"
                  :shouldClearAllForms="shouldClearAllForms"
                  @UnitPriceScheduleDone="handleUnitPriceScheduleDoneEvent"
                  @cancelCurrentForm="handleCancelCurrentFormEvent"
                  @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
                />
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Pénalités & Garantie
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="penaltiesInfosSetup"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>
                    
                  </span>
                </template>
                <contract-penalties
                  :contract="currentSelectedContract"
                  :penaltieTypes="penaltiesTypes"
                  @reloadPenaltiesTable="handleReloadPenaltiesTableEvent"
                />
                  <!-- @reloadPenaltiesTable="handleReloadPenaltiesTableEvent" -->
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Notifications & Alertes
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="notificationsInfosSetup"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>
                  </span>
                </template>
                <notificationsAndAlerts
                  :contract="currentSelectedContract"
                  :formDisabled="disableForm"
                  @notificationsInfoStepSaved="handleNotificationsInfoStepSavedEvent"
                  @cancelCurrentForm="handleCancelCurrentFormEvent"
                  :shouldClearAllForms="shouldClearAllForms"
                />
              </b-tab>

              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"
                    >Évaluation
                    <i
                      class="fas fa-check-circle text-success"
                      v-if="contractApproved"
                    ></i>
                    <i
                      class="fas fa-exclamation-triangle text-warning"
                      v-else
                    ></i>

                    <i v-if="currentSelectedContract.hasFollowUps" class="fas fa-exclamation-triangle text-warning"/>
                  </span>
                </template>
                <contract-review
                  :contract="currentSelectedContract"
                  @contractApproved="handleContractApprovedEvent"
                  @cancelCurrentForm="handleCancelCurrentFormEvent"
                  :shouldClearAllForms="shouldClearAllForms"
                />
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">
                    Synoptique
                    <i class="fas fa-info-circle text-info"></i>
                    
                  </span>
                </template>
                <contract-synoptic
                  :contract="currentSelectedContract"
                  @reloadSynopticsTable="handleSynopticInfoStepSavedEvent"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-model="newAmandmentContractModal.show"
      :title="`Ajouter un contrat avenant pour le contrat N°: `"
    >
      <form @submit.prevent="generateAmendmantContract">        
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
              <label for="">Type:</label>
                <multiselect
                  :searchable="true"
                  v-model="newAmandmentContractModal.type"
                  track-by="id"
                  label="name"
                  :options="newAmandmentContractModalTypes"
                  placeholder="Select Type"
                  :select-label="''"
                  :allow-empty="false"
                >
                  <template slot="singleType" slot-scope="{ type }">{{
                    type.name
                  }}</template>
                </multiselect>
              </div>
              <div class="col-md-3">
                <label for=""></label>
                <b-form-checkbox
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                      v-model="newAmandmentContractModal.isClosingAmnd"
                      plain
                    >Avenant de clôture</b-form-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h6>Copier les volets suivants:</h6>
              </div>
              
              <div class="col-md-3">
                <b-form-checkbox
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                      v-model="newAmandmentContractModal.copy.contractualScope"
                      plain
                    >Périmètre contractuel</b-form-checkbox>
              </div>
              <div class="col-md-3">
                <b-form-checkbox
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                      v-model="newAmandmentContractModal.copy.financeScope"
                      plain
                    >L'aspect Financier</b-form-checkbox>
              </div>

              <div class="col-md-3">
                <b-form-checkbox
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                      v-model="newAmandmentContractModal.copy.priceScope"
                      plain
                    >Borderau des prix</b-form-checkbox>
              </div>

              <div class="col-md-3">
                <b-form-checkbox
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                      v-model="newAmandmentContractModal.copy.notificationsAndAlerts"
                      plain
                    >Notifications & alertes</b-form-checkbox>
              </div>

              <div class="col-md-3">
                <b-form-checkbox
                      :value="true"
                      :unchecked-value="false"
                      class="mb-3"
                      v-model="newAmandmentContractModal.copy.synoptics"
                      plain
                    >Synoptique</b-form-checkbox>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </Layout>
</template>
