<script>
import Multiselect from "vue-multiselect";

import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'
export default {
  props: {
    refreshTableEvent: {
      type: Boolean,
      default: false,
    },
  },
  components: {Multiselect, Dataset, DatasetItem,/* DatasetInfo, */ DatasetPager,/* DatasetSearch, */ DatasetShow,},
  data() {
    return {
      tableData: [],
      selectedContract: null,
      selectedIndex:-1,
      contractFilterForm: {
        stdServices: [],
      },
      stdServicesTypes: [
        {
          id: 'prest_hosting',
          name: 'Hébergement',
        },
        {
          id: 'prest_catering',
          name: 'Restauration',
        },
        {
          id: 'prest_greenspace',
          name: 'Espace Vert',
        },
        {
          id: 'prest_threed_ops',
          name: 'Opérations 3D',
        },
        {
          id: 'prest_maintenance_of_facilities',
          name: 'Maintenance des Installations',
        },
        {
          id: 'prest_laundry_degreasing',
          name: 'Blanchisserie & Dégraissage',
        },
        {
          id: 'prest_maintenance_cleaning',
          name: 'Entretien & Nettoyage',
        },
        {
          id: 'prest_personal_transport',
          name: 'Transport du Personnel',
        },
        {
          id: 'prest_goods_transport',
          name: 'Transport de Marchandise',
        },
      ],
      title: "Contrats",
      contracts: [],
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],

      cols: [
        {
          name: "Référence ",
          field: "ref",
          sort: ''
        },
        {
          name: "Objet ",
          field: "objet",
          sort: ''
        },
        {
          name: "Fournisseur ",
          field: "supplier",
          sort: ''
        },
        {
          name: "Type Prestation ",
          field: "type",
          sort: ''
        },
        {
          name: "Montant alloué ",
          field: "amnt",
          sort: ''
        },
        {
          name: "Date début de validité ",
          field: "ddbv",
          sort: ''
        },
        {
          name: "Date d'expiration ",
          field: "dexp",
          sort: ''
        },
        {
          name: "Expiration Dans: ",
          field: "expityIn",
          sort: ''
        },
        {
          name: "Status ",
          field: "status",
          sort: ''
        },
      ],
    };
  },

  watch: {
    refreshTableEvent() {
      if (this.refreshTableEvent) {
        this.refreshContractsTable();
      }
    },

    selectedContract(event){
      if(this.selectedContract != null && event.length == 1){
        this.$emit('loadContractDataToFormComponent', this.selectedContract[0]);
      }else{
        this.$emit('clearAllContractForms', true);
      }
    }
  },

  mounted() {
    this.refreshContractsTable();
  },

  methods: {

    filterContractsList(){
      var selectedServices = this.contractFilterForm.stdServices;
      if(selectedServices.length == 0){
        this.$toast.warning("Vous devez sélectionner au moin un service pour pouvoir filter");
      }else{
        var loader = this.$loading.show();
        this.$http
        .post("/contracts/contracts/getFilteredContracts", {
          stdServices: selectedServices,
        })
        .then((res) => {
          loader.hide();
          this.contracts = res.data.list;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
      }
    },

    selectContractFromRow(selectedContract,index){
      if(this.selectedIndex == index) {
        // this.selectedRows = []; 
        this.selectedIndex = -1; 
        this.$emit('clearAllContractForms', true);
      }
      else {
        // this.selectedRows = [row];
        this.$emit('loadContractDataToFormComponent', selectedContract);
        this.selectedIndex = index;
      }
    },
    /**
     * Search the table data with search input
     */
    deleteContract(contRef, contUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer " + contRef + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/contracts/contracts/delete/" + contUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  Swal.fire("Félicitations!", res.data.original.msg, "success");
                  _this.refreshContractsTable();
                  break;

                case 500:
                  Swal.fire("Avertissement!", res.data.original.msg, "warning");
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshContractsTable() {
      this.$http
        .post("/contracts/contracts/list")
        .then((res) => (this.contracts = res.data.list))
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
  },
};
</script>

<template>
  <dataset v-slot="{ ds }" :ds-data="contracts" >
    <div class="row">
      <div class="col-md-4 mb-2 mb-md-0">
        <div class="form-group">
          <label for="formrow-inputCity">Type de Prestation *</label>
          <a href="javascrit:void(0)" @click="contractFilterForm.stdServices = []">Réinitialiser</a>
          <multiselect
                :searchable="true"
                :multiple="true"
                v-model="contractFilterForm.stdServices"
                class="here" 
                track-by="id"
                label="name"
                :options="stdServicesTypes"
                placeholder="Filtres"
                :select-label="''"
                :allow-empty="false"
              >
                <template slot="singleType" slot-scope="{ type }">{{
                  type.name
                }}</template>
              </multiselect>
              
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary mt-4" @click="filterContractsList">Filtrer</button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="">
          <table class="table table-hover d-md-table">
            <thead>
              <tr>
                <th v-for="(th) in cols" :style="th.field == 'objet' ? 'width: 20%;' : ''" :key="th.field">
                  {{ th.name }} 
                </th>
              </tr>
            </thead>
            <dataset-item tag="tbody">
              <template #default="{ row, rowIndex }">
                <tr @click="selectContractFromRow(row,rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer">
                  <td>{{ row.ref }}</td>
                  <td>{{ row.object }}</td>
                  <td>{{ row.supplier.name }}</td>
                  <td>{{ row.type }}</td>
                  <td>{{ row.totalAmnt }}</td>
                  <td>{{ row.startDate }}</td>
                  <td>{{ row.endDate }}</td>
                  <td><span v-html="row.expirationDeadline.days_html"></span></td>
                  <td><span v-html="row.status"></span></td>
                </tr>
              </template>
            </dataset-item>
          </table>
        </div>
      </div>
    </div>
    <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
      <!-- <dataset-info class="mb-2 mb-md-0" /> -->
      
        <dataset-show :ds-show-entries="5" />
      <dataset-pager />
    </div>
  </dataset>
</template>

<style>
.here .multiselect__content-wrapper{
    position:relative !important;
    max-height: 300px !important;
}
</style>