<template>
  <div class="">
    <fieldset :disabled="formDisabled">
      <div class="row">
      <div class="card-body">
        <fieldset :disabled="!events.allowStdServicesMatrix">
          <div class="row" ref="contractualScopeContainer">
            <div class="col-lg-12">
              <hr />
              <div class="table-responsive">
                <table class="table table-striped table-bordered mb-0 va-middle">
                  <thead>
                    <tr>
                      <th>Prestations Core</th>
                      <th v-for="base in lifebases" v-bind:key="base.id">
                        {{ base.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr
                      class=""
                      v-for="service in services"
                      v-bind:key="service.id"
                    >
                    <!-- ebsC tr -->
                      <th style="width:30%"  scope="row">
                        {{ service.name }}
                      </th>
                      <td v-for="base in lifebases" v-bind:key="base.id">
                        <b-form-checkbox
                          v-if="service.enabled"
                          switch
                          size="sm"
                          class=""
                          :checked="campHasService(base, service)"
                          @change="addServiceToLifebase(base, service)"
                        ></b-form-checkbox>
                        <span v-else>
                          <a href="javascript:void()">
                            <span v-b-popover.hover="`Le module: ${service.name} n'est pas pris en charge dans cette version`">
                              <i class="fas fa-exclamation-triangle text-warning"></i>
                            </span>
                          </a>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset :disabled="!events.allowCustomServicesMatrix">
          <div class="row" ref="contractualScopeContainer">
            <div class="col-lg-12">
              <hr />
              <div class="table-responsive">
                <table class="table table-striped table-bordered mb-0 va-middle" style="vertical-align:center">
                  <thead>
                    <tr>
                      <th style="width:30%">Prestations Autre</th>
                      <th v-for="base in lifebases" v-bind:key="base.id">
                        {{ base.name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="">
                    <tr
                      class=""
                      v-for="service in customServices"
                      v-bind:key="service.id"
                    >
                    <!-- ebsC tr -->
                      <th style="width:30%" scope="row">
                        <a href="javascript:void()" @click.prevent="editCustomService(service.uuid)" v-b-popover.hover="'CLiquer pour Modifier/Supprimer le service: '+ service.name"><span>{{ service.name }}</span></a>
                      </th>
                      <td v-for="base in lifebases" v-bind:key="base.id">
                        <b-form-checkbox
                          switch
                          size="sm"
                          class=""
                          :checked="service.camp_id == base.id"
                          @input="addCustomServiceToCamp(base, service)"
                        ></b-form-checkbox>
                          <!-- :checked="campHasCustomService(base, service)" -->

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <h4>
                <a
                  v-if="showValidationBtn && !formDisabled && events.allowCustomServicesMatrix"
                  @click.prevent="addNewServiceClick"
                  href="javascript:void(0)"
                  class=""
                  ><i class="fas fa-plus-circle"></i> Ajouter</a
                >
              </h4>
            </div>
          </div>
        </fieldset>

        <hr />

        <div class="row">
          <div class="col-md-12">
            <button v-if="showValidationBtn" type="button" @click="validateContractualScopeClick" class="btn btn-label btn-success float-right">
              <i class="bx bx-check-double label-icon"></i> Valider l'étape
            </button>
            <b-button v-if="showValidationBtn"  @click="cancelForm" variant="danger" class="btn btn-light btn-label  mr-2 float-right">
                <i class="fas fa-times-circle text-white label-icon"></i> Annuler
            </b-button>
          </div>
        </div>
      </div>
    </div>
    </fieldset>

    <!-- Other services Modal -->
    <Modal
      v-model="showNewOtherServiceModal"
      title="Ajouter une prestation / un service"
      @close="clearNewOtherServiceForm"
    >
      <form @submit.prevent="saveNewContractService">
        
        <div class="row" v-if="newCustomServiceForm.events.usePredefined">
          <div class="col-md-12">
            <div class="form-group">
              <label for="formrow-email-input">Prestation/Service *</label>
              <a @click.prevent="toggleNewServiceForm" v-if="newCustomServiceForm.events.usePredefined" class="float-right" href="">Ajouter</a>
              <multiselect
                :searchable="true"
                :multiple="true"
                v-model="newCustomServiceForm.predefinedServiceId"
                track-by="id"
                label="name"
                :options="newCustomServiceForm.predefinedServices"
                placeholder="Select Service"
                :select-label="''"
                :allow-empty="false"
                :class="{
                  'is-invalid': submitted && $v.newCustomServiceForm.predefinedServiceId.$error,
                }"
              >
                <template slot="singleType" slot-scope="{ type }">{{
                  type.name 
                }}</template>
              </multiselect>
            </div>
          </div>
        </div>

        <div class="row" v-if="newCustomServiceForm.events.createNew">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Type *</label>
              <input
                id="projectname"
                v-model="newCustomServiceForm.type"
                type="text"
                class="form-control"
                placeholder=""
                :class="{
                  'is-invalid':
                    submitted && $v.newCustomServiceForm.type.$error,
                }"
              />
              <div v-if="submitted && $v.newCustomServiceForm.type.$error" class="invalid-feedback">
                <span v-if="!$v.newCustomServiceForm.type.required">Champs Obligatoire.</span>
                <br />
                
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Code *</label>
              <input
                id="projectname"
                v-model="newCustomServiceForm.code"
                type="text"
                class="form-control"
                placeholder=""
                :class="{
                    'is-invalid':
                      submitted && $v.newCustomServiceForm.code.$error,
                  }"
                />
                <div v-if="submitted && $v.newCustomServiceForm.code.$error" class="invalid-feedback">
                  <span v-if="!$v.newCustomServiceForm.code.required">Champs Obligatoire.</span>
                  <br />
                  
                </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Désignation *</label>
              <input
                id="projectname"
                v-model="newCustomServiceForm.name"
                type="text"
                class="form-control"
                placeholder=""
                :class="{
                    'is-invalid':
                      submitted && $v.newCustomServiceForm.name.$error,
                  }"
                />
                <div v-if="submitted && $v.newCustomServiceForm.name.$error" class="invalid-feedback">
                  <span v-if="!$v.newCustomServiceForm.name.required">Champs Obligatoire.</span>
                  <br />
                  
                </div>
            </div>
          </div>

          <div class="col-md-12">
            <div class="form-group">
              <label for="formrow-email-input">Description *</label>
              <textarea
                id="projectname"
                v-model="newCustomServiceForm.comments"
                type="text"
                class="form-control"
                rows="2"
                placeholder=""
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ">
            <div class="float-right">
                <button
                  @click.prevent="deleteCustomService"
                  v-if="newCustomServiceForm.allowDeleteMode"
                  class="btn btn-danger "
                  type="submit"
                >
                  <i class="fas fa-trash"></i>
                  Supprimer
                </button>
                <button
                  @click.prevent="toggleNewServiceForm"
                  v-if="newCustomServiceForm.events.createNew && newCustomServiceForm.events.showCancelBtn"
                  class="btn btn-warning"
                  type="submit">
                  <i class="fas fa-window-close"></i>
                  Annuler
                </button>
                <button class="btn btn-primary ml-2" type="submit">
                  <i class="fas fa-save"></i>
                  Enregistrer
                </button>       
            </div>  
          </div>   
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import {
  required,
  decimal,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";

import Swal from "sweetalert2";
import { mapGetters } from 'vuex';

export default {
  props: {
    lifebases: {
      type: Array,
      default: null,
    },
    services: {
      type: Array,
      default: null,
    },
    formDisabled: {
      type: Boolean,
      default: true,
    },
    contract: {},
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.clearForm();
      }
    },
    contract(){
      if(this.contract){
        this.formDisabled = this.contract.statusPlain != 'draft'; 
        this._reloadCustomServicesBeta();
        this._refreshSelectedSevricesFromSelectedContract();
        this._refreshSelectedCustomServicesFromSelectedContract();
      }
    },
    selectedSevrices(){
      if(this.selectedSevrices.length > 0){
        this.events.allowStdServicesMatrix = true;
        this.events.allowCustomServicesMatrix = false;
      }else{
        this.events.allowStdServicesMatrix = true;
        this.events.allowCustomServicesMatrix = true;
      }
    },
    selectedCustomSevrices(){
      if(this.selectedCustomSevrices.length > 0){
        this.events.allowStdServicesMatrix = false;
        this.events.allowCustomServicesMatrix = true;
      }else{
        this.events.allowStdServicesMatrix = true;
        this.events.allowCustomServicesMatrix = true;
      }
    }
  },
  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn(){
      var show = true;
      if(this.contract){
        show = this.contract.statusPlain == 'draft'|| this.user.role.id == 1;
      }
      return show;
    },
  },
  components: {Multiselect},
  data() {
    return {
      title: "Contrats",
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      newCustomServiceForm: {
        type: "",
        code: "",
        name: "",
        comments: "",
        predefinedServiceId: [],
        events: {
          usePredefined: true,
          createNew: false,
          showCancelBtn: true,
        },
        predefinedServices: [],
      },
      events: {
        allowStdServicesMatrix: true,
        allowCustomServicesMatrix: true,
      },
      customServices: [],
      showNewOtherServiceModal: false,
      showOtherServicesTable: false,
      showDeleteButtonOnServiceModal: false,
      lifebaseHasServices: {},
      lifebaseHasCustomServices: {},
      selectedSevrices: [],
      selectedCustomSevrices: [],
      selectedBases: [],
      matrix: {},
      contractForm: {},
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },

  mounted() {
    this._prepare_matrix();
    // this._reloadCustomServicesBeta();
  },

  methods: {

    cancelForm(){
      this.$emit('cancelCurrentForm', true);
    },

    toggleNewServiceForm(){
      this.newCustomServiceForm.events.usePredefined = !this.newCustomServiceForm.events.usePredefined;
      this.newCustomServiceForm.events.createNew = !this.newCustomServiceForm.events.createNew;
    },

    clearForm(){
      this.services.forEach(element => {
        element.checked = false;
      });
      this.customServices = [];
      this.lifebaseHasServices = {};
      this.lifebaseHasCustomServices = {};
      this.selectedSevrices = [];
      this.selectedCustomSevrices = [];
      this.selectedBases = [];
      this.matrix = {};
    },

    clearNewOtherServiceForm(){
      this.newCustomServiceForm.type = "";
      this.newCustomServiceForm.code = "";
      this.newCustomServiceForm.name = "";
      this.newCustomServiceForm.comments = "";
    },
    _prepare_matrix(){
      var matrix = this.matrix;
      var camps = this.lifebases;
      var services = this.services;
      services.forEach((service) => {
        if(!matrix[service.id]){
          var campsData = [];
          camps.forEach((camp) => {
            campsData.push({
              campId: camp.id,
              campName: camp.name,
              campSelected: false,
            });
          });
          matrix[service.id] = campsData;
        }
      });
      this.matrix = matrix;
    },
    useLifebase(id) {
      this.lifebases.forEach((element) => {
        if (element.id == id) {
          if (element.toBeUsed) {
            element.toBeUsed = false;
          } else {
            element.toBeUsed = true;
          }
        }
      });
    },

    otherServiceChecked(id) {
      if (id == 10) {
        if (this.showOtherServicesTable) {
          this.showOtherServicesTable = false;
        } else {
          this.showOtherServicesTable = true;
        }
      }
    },

    addNewServiceClick() {
      this.loadCustomServicesList();
      this.newCustomServiceForm.events.usePredefined = true;
      this.newCustomServiceForm.events.createNew = false;
      this.newCustomServiceForm.servId = null;
      this.newCustomServiceForm.allowDeleteMode = false;
      this.showNewOtherServiceModal = true;
    },

    editCustomService(id) {
      if(this.contract.statusPlain == 'draft'){
        var loader = this.$loading.show();
        // get service details
        this.$http.post("/contracts/contracts/getCustomServiceDetails", {
          servId: id,
        }).then((res) => {
            loader.hide();
            var service = res.data.original.service;
            this.newCustomServiceForm.type = service.type;
            this.newCustomServiceForm.code = service.code;
            this.newCustomServiceForm.name = service.name;
            this.newCustomServiceForm.comments = service.comments;
            this.newCustomServiceForm.events.usePredefined = false;
            this.newCustomServiceForm.events.createNew = true;
            this.newCustomServiceForm.events.showCancelBtn = false;
            this.newCustomServiceForm.servId = service.uuid;
            this.newCustomServiceForm.allowDeleteMode = true;
            this.showNewOtherServiceModal = true;
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }else{
        this.$toast.error("Impossible de modifier ce service car le contrat <strong>"+ this.contract.ref +"</strong> n'est pas en mode modification");
      }
    },

    _serviceIsNotAssignedToAnyCamp(serviceId){
      var notAssigned = true;
      var campServices = this.lifebaseHasServices;
      for (var campId of Object.keys(campServices)) {
        var SingleCampServices = campServices[campId];
        if(SingleCampServices.includes(serviceId.id)){
          notAssigned = false;
        }
      }
      return notAssigned;
    },

    _removeSelectedService(serviceId){
      var i = 0;
      // check if service amount in finance scope == 0 or null
      this.selectedSevrices.forEach(element => {
        if(element.id == serviceId.id){
          // check if this service is not assigned to cany camp
          var serviceNotAssigned = this._serviceIsNotAssignedToAnyCamp(serviceId);
          if(serviceNotAssigned){
            this.selectedSevrices.splice(i, 1);
          }
        }
        i++;
      });
    },

    _removeSelectedBase(base){
      var i = 0;
        this.selectedBases.forEach(element => {
            if(element.id == base.id){
              this.selectedBases.splice(i, 1);
            }
            i++;
        });
    },

    _serviceIsSelected(service){
      var selected = false;
        this.selectedSevrices.forEach(element => {
        if(element.id == service.id){
          selected = true;
        }
      });
      return selected;
    },

    _customServiceIsSelected(){

    },

    _setServiceToSelected(service) {
      if (!this._serviceIsSelected(service)) {
        // add service to array
        this.selectedSevrices.push(service);
      } else {
        // remove service from arrat
        this._removeSelectedService(service);
      }
      this.$emit("selectedContractSevricesChanged", this.selectedSevrices);
    },

    _setBaseToBeSelected(base) {
      if (!this.selectedBases.includes(base)) {
        this.selectedBases.push(base);
      }else{
        this._removeSelectedBase(base);
      }
      this.$emit("selectedContractLifeBasesChanged", this.selectedBases);
    },

    _refreshLifebaseHasServices(base, service){
      var currentData = this.lifebaseHasServices;
      var baseId = base.uuid;
      var serviceId = service.id;
      var alreasyAsignedServices = [];
      if(currentData[baseId]){
        alreasyAsignedServices = currentData[baseId];
      }else{
        currentData[baseId] = [];
      }
      if(!alreasyAsignedServices.includes(serviceId)){
        alreasyAsignedServices.push(serviceId);
      }else{
        var i = 0;
        alreasyAsignedServices.forEach(element => {
          if(element == service.id){
            alreasyAsignedServices.splice(i, 1);
          }
          i++;
        });
      }
      currentData[baseId] = alreasyAsignedServices;
      this.lifebaseHasServices = currentData;
    },


    _refreshLifebaseHasServicesFromSelectedContract(){
      var campHasService = {};
      this.lifebaseHasServices = {};
      var contractCampServices = this.contract.selectedCampsWithServices;
      contractCampServices.forEach(campWithServices => {
          var services = campWithServices.services.map(element => { return element.id; });
          campHasService[campWithServices.uuid] = services;
      });
      this.lifebaseHasServices = campHasService;
    },
    _refreshSelectedStdServicesFromContract(){
      var selectedServices = [];
      var contractCampServices = this.contract.selectedCampsWithServices;
      contractCampServices.forEach(singleCamp => {
        var singleCampServices = singleCamp.services;
        var serviceAlreadyExist = false;
        // singleCampServices.forEach(service => {
        //   if (!selectedServices.includes(service.id)) {
        //     selectedServices.push(service.id);
        //   }
        // });

        singleCampServices.forEach(service => {
          selectedServices.forEach(oldService => {
            if(oldService.id == service.id){
              serviceAlreadyExist = true;
            }
          });
          if(!serviceAlreadyExist){
            selectedServices.push(service);
          }
        });
      });
      this.selectedSevrices = selectedServices;
    },


    // Custom services from contract
    _refreshLifebaseHasCustomServicesFromSelectedContract(){

    },
    _refreshCustomSelectedStdServicesFromContract(){

    },

    _refreshSelectedCustomServicesFromSelectedContract(){
      this._refreshLifebaseHasCustomServicesFromSelectedContract();
      this._refreshCustomSelectedStdServicesFromContract();
    },

    _refreshSelectedSevricesFromSelectedContract(){
      this._refreshLifebaseHasServicesFromSelectedContract();
      this._refreshSelectedStdServicesFromContract();
    },

    campHasService(camp, service){
      var hasService = false;
      if(this.contract){
        // this._refreshSelectedSevricesFromSelectedContract();
        var selectedCampsIds = this.contract.selectedCampsWithServices.map(element => { return element.id; });
        if(selectedCampsIds.includes(camp.id)){
          var campServices = this.contract.selectedCampsWithServices.filter(element => element.id == camp.id).map(element => { return element.services; })[0];
          var campServicesIds = campServices.map(element => { return element.id });
          return campServicesIds.includes(service.id);
        }
      }
      return hasService;
    },

    // campHasCustomService(camp, service){
      
    // },


    validateContractualScopeClick(){
      // send dara to API
      // if(Object.keys(this.lifebaseHasServices).length > 0 || Object.keys(this.lifebaseHasCustomServices).length > 0){
        if(this.selectedSevrices.length > 0 || this.selectedCustomSevrices.length > 0){
        let loader = this.$loading.show({container: this.$refs.contractualScopeContainer});
        this.$http.post("/contracts/contracts/saveContractualScopeStep",{
          contractId: this.contract.id,
          data: this.lifebaseHasServices,
          customServices: this.lifebaseHasCustomServices,
        }).then((res) => {
          loader.hide();
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success(res.data.original.msg);
              this.$emit('ContractualScopeSaved', true);
              break;
  
            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
        }else{
        this.$toast.warning("Pour pouvoir continuer, vous devez selectionner au moins Une Prestations Core Ou bien une Prestations Autre");
      }
    },

    _reloadCustomServicesBeta(){
      var loader = this.$loading.show();
      this.$http.post("/contracts/contracts/customServicesList_Beta", {contractId: this.contract.uuid}).then((res) => {
          loader.hide();
          this.customServices = res.data.list;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    _reloadCustomServices(){
      var loader = this.$loading.show();
      this.$http.post("/contracts/contracts/customServicesList").then((res) => {
          loader.hide();
          this.customServices = res.data.original.list;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    deleteCustomService(){
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le service: " + this.newCustomServiceForm.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
           var loader = this.$loading.show();
          this.$http
            .post("/contracts/contracts/deleteCustomService", {
              servId: this.newCustomServiceForm.servId,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                    this.clearNewOtherServiceForm();
                    this._reloadCustomServicesBeta();
                    this.showNewOtherServiceModal = false;
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    saveNewContractService(){
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var loader = this.$loading.show();
        this.$http.post("/contracts/contracts/saveNewCustomService",{
            contractId: this.contract.id,
            data: this.newCustomServiceForm
          }).then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this._reloadCustomServicesBeta();
                this.clearNewOtherServiceForm();
                this.showNewOtherServiceModal = false;
                break;
    
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    addServiceToLifebase(base, service) {
      this._refreshLifebaseHasServices(base, service);
      this._setServiceToSelected(service);      
    },

    _customServiceIsNotAssigned(serviceId){
        var notAssigned = true;
        var campServices = this.lifebaseHasCustomServices;
        for (var campId of Object.keys(campServices)) {
          var SingleCampServices = campServices[campId];
          if(SingleCampServices.includes(serviceId.id)){
            notAssigned = false;
          }
        }
        return notAssigned;
    },

    _removeSelectedCustomService(serviceId){
      var i = 0;
      this.selectedCustomSevrices.forEach(element => {
        if(element.id == serviceId.id){
          if(this._customServiceIsNotAssigned(serviceId)){
            this.selectedCustomSevrices.splice(i, 1);
          }
        }
        i++;
      });
    },

    _customServiceIsNotSelected(service){
      var selected = false;
        this.selectedCustomSevrices.forEach(element => {
        if(element.id == service.id){
          selected = true;
        }
      });
      return selected;
    },

    _setCustomServiceToSelected(service){
      if (!this._customServiceIsNotSelected(service)) {
        // add service to array

        this.selectedCustomSevrices.push(service);
      } else {

        // remove service from arrat
        this._removeSelectedCustomService(service);
      }
      this.$emit("selectedContractCustomSevricesChanged", this.selectedCustomSevrices); // @TODO; fix this
    },

    _removeServiceFromCamp(base, service){
      var data = this.lifebaseHasCustomServices;
      var baseId = base.uuid;
      var baseServices = data[baseId];
      var i = 0;
      baseServices.forEach(element => {
            if(element ==service.id){
              baseServices.splice(i, 1);
              i++;
            }
        });
    },
    
    _refreshLifebaseHasCustomServices(base, service){
      var currentData = this.lifebaseHasCustomServices;
      var baseId = base.uuid;
      var serviceId = service.id;
      var alreasyAsignedServices = [];
      if(currentData[baseId]){
        alreasyAsignedServices = currentData[baseId];
      }else{
        currentData[baseId] = [];
      }
      if(!alreasyAsignedServices.includes(serviceId)){
        alreasyAsignedServices.push(serviceId);
      }else{
        this._removeServiceFromCamp(base, service);
      }
      currentData[baseId] = alreasyAsignedServices;
      this.lifebaseHasCustomServices = currentData;
    },

    addCustomServiceToCamp(camp, service){
      this._setCustomServiceToSelected(service);
      // this._setBaseToBeSelected(base);
      this._refreshLifebaseHasCustomServices(camp, service);
    },

    loadCustomServicesList(){
      // newCustomServiceForm.predefinedServices
      var loader = this.$loading.show();
      this.$http.post("/contracts/contracts/getCustomServicesList", {contractId: this.contract.uuid}).then((res) => {
          loader.hide();
          this.newCustomServiceForm.predefinedServices = res.data.original.list;
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
  },

  validations: {
    newCustomServiceForm: {
      type: { 
        required: requiredIf(function() {
          return !this.newCustomServiceForm.events.usePredefined
        })
       },
      code: { 
        required: requiredIf(function() {
          return !this.newCustomServiceForm.events.usePredefined
        })
       },
      name: { 
        required: requiredIf(function() {
          return !this.newCustomServiceForm.events.usePredefined
        })
       },
      predefinedServiceId: {
        required: requiredIf(function() {
          return this.newCustomServiceForm.events.usePredefined
        })
      }
    },
  },
};
</script>
