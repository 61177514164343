<template>
  <div>
    <b-tab class="border-0">
      <template v-slot:title>
        <span class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block">
          Espace Vert
        </span>
      </template>
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Montant alloué</label>
            <a href="" style="float:right;">Enregistrer</a>
            <input
              type="text"
              style="display:inline-block"
              class="form-control"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <price-check :allocatedAmnt="contract.amounts.greenspace_amnt.plain" :items="data"/>
      <v-table :data="data" class="table">
        <thead slot="head">
          <th>Type prestation</th>
          <th>Désignation</th>
          <th>Unité de mesure</th>
          <th>Type de contrôl</th>
          <th>Prix unitaire</th>
          <th>Qté. Prévision</th>
          <th>Type Prévision</th>
          <th>Qté. Annuelle</th>
          <th>Qté. Contrat</th>
          <th>Actions</th>
        </thead>
        <tbody slot="body" slot-scope="{ displayData }">
          <tr v-for="row in displayData" :key="row.id">
            <td>{{ row.serviceType }}</td>
            <td>{{ row.name }}</td>
            <td>
              <span class="badge badge-primary">{{ row.uom }}</span>
            </td>
            <td>
              <span class="badge badge-info">{{ row.control_type.name }}</span>
            </td>
            <td>{{ row.unit_price.withCurrency }}</td>
            <td>{{ row.estimated_qty.formated }}</td>
            <td>
              <span class="badge badge-info">{{
                row.estimation_type.name
              }}</span>
            </td>
            <td>{{ row.yearly_qty.formated }}</td>
            <td>{{ row.contractual_qty.formated }}</td>
            <td>
              <a href="javascript:void(0)" v-if="contract.statusPlain == 'draft'" @click="editSingleService(row.id)"
                ><i class="fas fa-edit"></i
              ></a>
              <a href="javascript:void(0)" v-else @click="editSingleService(row.id)">
                <i class="fas fa-eye"></i>
              </a>
              <a
                href="javascript:void(0)" v-if="contract.statusPlain == 'draft'"
                @click="deleteServiceFromContract(row.name, row.id)"
                ><i class="fas fa-trash text-danger"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </v-table>
      <div class="row">
        <div class="col-md-12">
          <h5>
            <a
              v-if="contract.statusPlain == 'draft'"
              @click.prevent="addNewHstServiceClick"
              href="javascript:void(0)"
              class=""
              ><i class="fas fa-plus-circle"></i> Ajouter</a
            >
          </h5>
        </div>
      </div>
    </b-tab>

    <Modal
      v-model="showNewHostingService"
      title="Ajouter une prestation Espace Vert"
      ref="newRestaurationServiceModal"
      @close="_clearNewServiceForm"
    >
      <form @submit.prevent="saveNewServiceToContract">
        
        <fieldset :disabled="newHostingServiceModal.readMode">
          <div class="row">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type de prestation *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.serviceType"
                    track-by="id"
                    label="designation"
                    :select-label="''"
                    :options="newHostingServiceModal.serviceTypesList"
                    placeholder="Select Type de prestation"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.serviceType.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.serviceType.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.serviceType.required
                      "
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4" v-if="newHostingServiceModal.formData.serviceType.designation == 'Entretien'">
                <div class="form-group">
                  <label for="formrow-email-input">Type de Ressource *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.resourceType"
                    track-by="id"
                    label="name"
                    :select-label="''"
                    :options="newHostingServiceModal.resourceTypesList"
                    placeholder="Select Type de Ressource"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.resourceType.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.resourceType.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.resourceType.required
                      "
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4" v-if="newHostingServiceModal.formData.resourceType.id == 'human'">
                <div class="form-group">
                  <label for="formrow-email-input">Poste *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.position"
                    track-by="id"
                    label="designation"
                    :select-label="''"
                    :options="newHostingServiceModal.positionsList"
                    placeholder="Select Poste"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.position.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.position.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.position.required
                      "
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-8" v-if="newHostingServiceModal.formData.serviceType.designation != 'Matériel roulant' && newHostingServiceModal.formData.serviceType.designation != 'Entretien' && newHostingServiceModal.formData.serviceType.designation != 'Fournitures des produits phytosanitaires'">
                <div class="form-group">
                  <label for="formrow-email-input">Désignation *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.name"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.name.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.name.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.newHostingServiceModal.formData.name.required"
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <!-- PRODUITS -->
              <div class="col-md-6" v-if="newHostingServiceModal.formData.serviceType.designation == 'Fournitures des produits phytosanitaires'">
                <div class="form-group">
                  <label for="formrow-email-input">Catégorie *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.productCategId"
                    track-by="id"
                    label="name"
                    :options="newHostingServiceModal.productCategsList"
                    placeholder="Select Product category"
                    :select-label="''"
                    @input="loadProductsViaCategory"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.productCategId.$error,
                    }"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="submitted && $v.newHostingServiceModal.formData.productCategId.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newHostingServiceModal.formData.productCategId.required"
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="newHostingServiceModal.formData.serviceType.designation == 'Fournitures des produits phytosanitaires'">
                <div class="form-group">
                  <label for="formrow-email-input">Produit *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.productId"
                    track-by="id"
                    label="name"
                    :options="newHostingServiceModal.productsList"
                    :select-label="''"
                    placeholder="Select Product"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.productId.$error,
                    }"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="submitted && $v.newHostingServiceModal.formData.productId.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newHostingServiceModal.formData.productId.required"
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <!-- EQUIPEMENTS -->
              <div class="col-md-6" v-if="newHostingServiceModal.formData.serviceType.designation == 'Matériel roulant' || newHostingServiceModal.formData.resourceType.id == 'equipment'">
                <div class="form-group">
                  <label for="formrow-email-input">Catégorie d'équipement *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.equiCategId"
                    track-by="id"
                    label="designation"
                    :options="newHostingServiceModal.equCategsList"
                    :select-label="''"
                    placeholder="Select Equipment category"
                    @input="loadEquipmentssViaCategory"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.equiCategId.$error,
                    }"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div
                    v-if="submitted && $v.newHostingServiceModal.formData.equiCategId.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newHostingServiceModal.formData.equiCategId.required"
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>
              <div class="col-md-6" v-if="newHostingServiceModal.formData.serviceType.designation == 'Matériel roulant' || newHostingServiceModal.formData.resourceType.id == 'equipment'">
                <div class="form-group">
                  <label for="formrow-email-input">Equipement *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.equipmentId"
                    track-by="id"
                    label="name"
                    :options="newHostingServiceModal.equipmentsList"
                    :select-label="''"
                    placeholder="Select Product"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.equipmentId.$error,
                    }"
                    :allow-empty="false"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="submitted && $v.newHostingServiceModal.formData.equipmentId.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newHostingServiceModal.formData.equipmentId.required"
                      >Champ obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Unité de mesure *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newHostingServiceModal.formData.Uom"
                    track-by="id"
                    label="designation"
                    :options="newHostingServiceModal.UomsList"
                    :select-label="''"
                    placeholder="Select Unité de mesure"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.Uom.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted && $v.newHostingServiceModal.formData.Uom.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="!$v.newHostingServiceModal.formData.Uom.required"
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type contrôle *</label>
                  <multiselect
                    :searchable="true"
                    v-model="
                      newHostingServiceModal.formData.contractControlType
                    "
                    track-by="id"
                    label="name"
                    :options="newHostingServiceModal.contractControlTypesList"
                    :select-label="''"
                    placeholder="Select Type contrôle"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.contractControlType
                          .$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.contractControlType
                          .$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.contractControlType
                          .required
                      "
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="formrow-email-input">Type prévision *</label>
                  <multiselect
                    :searchable="true"
                    v-model="
                      newHostingServiceModal.formData.contractEstimationType
                    "
                    track-by="id"
                    label="name"
                    :select-label="''"
                    :options="
                      newHostingServiceModal.contractEstimationTypesList
                    "
                    placeholder="Select Type prévision"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData
                          .contractEstimationType.$error,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.name
                    }}</template>
                  </multiselect>
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData
                          .contractEstimationType.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData
                          .contractEstimationType.required
                      "
                      >Champs obligatoire.</span
                    >
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Prix Unitaire *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.unitPrice"
                    class="form-control"
                    name=""
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.unitPrice.$error,
                    }"
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.unitPrice.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.unitPrice.required
                      "
                      >Champs obligatoire.</span
                    >
                    <span v-if=" !$v.newHostingServiceModal.formData.unitPrice.decimal">Champs Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté. Prévision *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.estimatedQty"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.estimatedQty.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.estimatedQty.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.estimatedQty
                          .required
                      "
                      >Champs obligatoire.</span
                    >
                    <span v-if=" !$v.newHostingServiceModal.formData.estimatedQty.decimal">Champs Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté. Annuelle *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.annualQty"
                    class="form-control"
                    name=""
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.annualQty.$error,
                    }"
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.annualQty.$error
                    "
                    class="invalid-feedback"
                  >
                    <span v-if=" !$v.newHostingServiceModal.formData.annualQty.required">Champs obligatoire.</span>
                    <span v-if=" !$v.newHostingServiceModal.formData.annualQty.decimal">Champs Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté. Contrat *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.contractualQty"
                    class="form-control"
                    name=""
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.contractualQty
                          .$error,
                    }"
                    id=""
                  />
                  <div
                    v-if="
                      submitted &&
                        $v.newHostingServiceModal.formData.contractualQty.$error
                    "
                    class="invalid-feedback"
                  >
                    <span
                      v-if="
                        !$v.newHostingServiceModal.formData.contractualQty
                          .required
                      "
                      >Champs obligatoire.</span
                    >
                    <span v-if=" !$v.newHostingServiceModal.formData.contractualQty.decimal">Champs Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Qté Min *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.minQty"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.minQty.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div v-if="submitted && $v.newHostingServiceModal.formData.minQty.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newHostingServiceModal.formData.minQty.required">Champs obligatoire.</span>
                    <span v-if="!$v.newHostingServiceModal.formData.minQty.decimal">Champs Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Unité d'oeuvre *</label>
                  <input
                    type="text"
                    v-model="newHostingServiceModal.formData.workUnit"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newHostingServiceModal.formData.workUnit.$error,
                    }"
                    name=""
                    id=""
                  />
                  <div v-if="submitted && $v.newHostingServiceModal.formData.workUnit.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.newHostingServiceModal.formData.workUnit.required">Champs obligatoire.</span>
                    <span v-if="!$v.newHostingServiceModal.formData.workUnit.decimal">Champs Invalide.</span>
                    <br />
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        </fieldset>
        <div v-if="!newHostingServiceModal.readMode" class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import priceCheck from "./components/priceCheck";

import { fetchEquipmentsWithCategoryListApi, getDropDownElementsByTypeApi, fetchProductsWithCategoryListApi, fetchProductsCategoriesListApi, fetchEquipmentsCategsApi} from "@/api/common";
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
export default {
  components: { Multiselect, priceCheck },
  import: { fetchEquipmentsWithCategoryListApi, getDropDownElementsByTypeApi, fetchProductsWithCategoryListApi, fetchProductsCategoriesListApi, fetchEquipmentsCategsApi },
  props: {
    contract: {},
    camp: {},
    service: {},
  },
  data() {
    return {
      isModalLoading: false,
      submitted: false,
      data: [],
      newHostingServiceModal: {
        readMode: false,
        serviceTypesList: [],
        resourceTypesList: [],
        roomTypesList: [],
        positionsList: [],
        UomsList: [],
        contractControlTypesList: [],
        contractEstimationTypesList: [],
        productCategsList: [],
        productsList: [],

        equCategsList: [],
        equipmentsList: [],
        formData: {
          name: "",
          serviceType: "",
          resourceType: "",
          position: "",
          Uom: "",
          contractControlType: "",
          contractEstimationType: "",
          annualQty: "",
          contractualQty: "",
          unitPrice: "",
          estimatedQty: "",
          service: "",
          productCategId: "",
          productId: "",
          equiCategId: "",
          equipmentId: "",
          minQty : "",
          workUnit : "",
        },
      },
      showNewHostingService: false,
    };
  },

  watch: {
    showNewHostingService() {
      if (this.showNewHostingService) {
        this.loadnewHostingServiceModalData();
      }
    },
  },

  mounted() {
    this.reloadContractServices();
  },

  methods: {

    _clearNewServiceForm(){
      this.submitted = false;
      this.newHostingServiceModal.formData.name = "";
      this.newHostingServiceModal.formData.serviceType = "";
      this.newHostingServiceModal.formData.resourceType = "";
      this.newHostingServiceModal.formData.position = "";
      this.newHostingServiceModal.formData.Uom = "";
      this.newHostingServiceModal.formData.contractControlType = "";
      this.newHostingServiceModal.formData.contractEstimationType = "";
      this.newHostingServiceModal.formData.annualQty = "";
      this.newHostingServiceModal.formData.contractualQty = "";
      this.newHostingServiceModal.formData.unitPrice = "";
      this.newHostingServiceModal.formData.estimatedQty = "";
      this.newHostingServiceModal.formData.service = "";
      this.newHostingServiceModal.formData.productCategId = "";
      this.newHostingServiceModal.formData.productId = "";
      this.newHostingServiceModal.formData.minQty = "";
      this.newHostingServiceModal.formData.workUnit = "";
      this.newHostingServiceModal.formData.equiCategId = "";
      this.newHostingServiceModal.formData.equipmentId = "";
      this.newHostingServiceModal.formData.isUpdate = false;
      this.newHostingServiceModal.formData.servId = null;
      this.newHostingServiceModal.readMode = false;
    },

    loadProductsViaCategory(){
      var loader = this.$loading.show();
      fetchProductsWithCategoryListApi(this.newHostingServiceModal.formData.productCategId.id)
      .then((res) => {
        loader.hide();
        this.newHostingServiceModal.productsList = res.data.products;
      })
      .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    loadEquipmentssViaCategory(){
      var loader = this.$loading.show();
      fetchEquipmentsWithCategoryListApi(this.newHostingServiceModal.formData.equiCategId.id)
      .then((res) => {
        loader.hide();
        this.newHostingServiceModal.equipmentsList = res.data.list;
      })
      .catch((error) => {
        loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    addNewHstServiceClick() {
      this.showNewHostingService = true;
    },
    reloadContractServices() {
      var campId = this.camp.id;
      var contractId = this.contract.id;
      // var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/getContractServicesInCamp", {
          campId: campId,
          contractId: contractId,
        })
        .then((res) => {
          // loader.hide();
          var status = res.data.status;
          var services = res.data.greenSpaceServices;
          switch (status) {
            case 200:
              this.data = services;
              
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          // loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    loadnewHostingServiceModalData() {
      this.fetchBaseData();
    },

    fetchBaseData() {
      var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/fetchBaseData")
        .then((res) => {
          loader.hide();
          this.newHostingServiceModal.UomsList = res.data.uoms;
          this.newHostingServiceModal.contractControlTypesList = res.data.controlTypes;
          this.newHostingServiceModal.contractEstimationTypesList = res.data.estimationTypes;
          this.newHostingServiceModal.serviceTypesList = res.data.gs_serviceTypesList;
          this.newHostingServiceModal.resourceTypesList = res.data.gs_resourceTypesList;
          this.newHostingServiceModal.positionsList = res.data.positions;
          this.newHostingServiceModal.equCategsList = res.data.equCategsList;
          this.newHostingServiceModal.productCategsList = res.data.productsCategs;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    saveNewServiceToContract() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.newHostingServiceModal.formData.service = this.service;
        this.newHostingServiceModal.formData.contract = this.contract;
        this.newHostingServiceModal.formData.camp = this.camp;
        var loader = this.$loading.show();
        this.$http
          .post(
            "/contracts/contracts/saveUnitPriceData",
            this.newHostingServiceModal.formData
          )
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this._clearNewServiceForm();
                this.showNewHostingService = false;
                this.$emit('reloadContractServicesStatus', true);
                this.reloadContractServices();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }else{
        alert("invalid");
      }

      // }
    },

    editSingleService(servId) {
      // get service details
      var loader = this.$loading.show({ zIndex: 1700 });
      this.$http
        .post("/contracts/contracts/getSingleServiceDetails", {
          servId: servId,
          service: this.service,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var service = res.data.service;
          switch (status) {
            case 200:
              // this.$toast.success(res.data.original.msg);
              this.newHostingServiceModal.formData.name = service.name;
              this.newHostingServiceModal.formData.resourceType = service.maintenance_resource_type_obj;
              this.newHostingServiceModal.formData.position = service.jobTitle;
              this.newHostingServiceModal.formData.annualQty =
                service.yearly_qty.plain;
              this.newHostingServiceModal.formData.contractualQty =
                service.contractual_qty.plain;
              this.newHostingServiceModal.formData.unitPrice =
                service.unit_price.plain;
              this.newHostingServiceModal.formData.estimatedQty =
                service.estimated_qty.plain;

              this.newHostingServiceModal.formData.serviceType =
                service.serviceTypeObj;
              this.newHostingServiceModal.formData.roomType =
                service.roomTypeObj;
              this.newHostingServiceModal.formData.contractControlType = service.control_type;
              this.newHostingServiceModal.formData.contractEstimationType = service.estimation_type;
              if(service.product){
                this.newHostingServiceModal.formData.productCategId = service.product.category;
                this.newHostingServiceModal.formData.productId = service.product;
              }
              
              this.newHostingServiceModal.formData.minQty = service.default_qty;
              this.newHostingServiceModal.formData.workUnit = service.internal_work_unit;

              this.newHostingServiceModal.formData.Uom = service.uomObj;
              this.newHostingServiceModal.formData.isUpdate = true;
              this.newHostingServiceModal.formData.servId = service.id;
              this.newHostingServiceModal.readMode = this.contract.statusPlain != 'draft';

              this.showNewHostingService = true;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    deleteServiceFromContract(servName, servId) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer Le service: '" + servName + "' ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show({ zIndex: 1700 });
          this.$http
            .post("/contracts/contracts/deleteServiceFromContract", {
              servId: servId,
              contract: this.contract.id,
              camp: this.camp.id,
              type: "green_space",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit('reloadContractServicesStatus', true);
                  this.reloadContractServices();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },

  validations: {
    newHostingServiceModal: {
      formData: {
        name: { 
           required: requiredIf(function() {
            return this.newHostingServiceModal.formData.serviceType.designation != 'Entretien' && this.newHostingServiceModal.formData.serviceType.designation != 'Fournitures des produits phytosanitaires' && this.newHostingServiceModal.formData.serviceType.designation != 'Matériel roulant';
          }),
         },
        annualQty: { required, decimal },
        contractualQty: { required, decimal },
        unitPrice: { required, decimal },
        estimatedQty: { required, decimal },
        serviceType: { required },
        minQty: { required, decimal },
        workUnit: { required, decimal },
        Uom: { required },
        contractControlType: { required },
        contractEstimationType: { required },
        productId: {
          required: requiredIf(function() {
            return this.newHostingServiceModal.formData.serviceType.designation == 'Fournitures des produits phytosanitaires';
          }),
        },
        productCategId: {
          required: requiredIf(function() {
            return this.newHostingServiceModal.formData.serviceType.designation == 'Fournitures des produits phytosanitaires';
          }),
        },
        
        equiCategId: {
          required: requiredIf(function() {
            return this.newHostingServiceModal.formData.serviceType.designation == 'Matériel roulant';
          }),
        },
        equipmentId: {
          required: requiredIf(function() {
            return this.newHostingServiceModal.formData.serviceType.designation == 'Matériel roulant';
          }),
        },

        resourceType: {
          required: requiredIf(function() {
            return this.newHostingServiceModal.formData.serviceType.designation == 'Entretien';
          }),
        },

        position: {
          required: requiredIf(function() {
            return this.newHostingServiceModal.formData.resourceType.id == 'human';
          }),
        },
        
      },
    },
  },
};
</script>
