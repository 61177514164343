<template>
  <b-tab class="border-0">
      <template v-slot:title>
        <span class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block">Blanchisserie & Dégraissage</span>
      </template>
      <!-- Content goes here -->
    </b-tab>
</template>

<script>
export default {};
</script>
