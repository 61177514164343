<template>
  <div>
    <fieldset :disabled="formDisabled">
      <div class="row" ref="generalInfosContainer">
      <div class="card-body">
        <div v-if="validationErrors.length > 0" role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger">
          <ul style="margin-bottom: 0rem;">
           <li v-for="(value, key, index) in validationErrors" v-bind:key="index">
             {{ value }}
           </li>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">N° Contrat *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractRef"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.contractRef.$touch"
                :class="{
                  'is-invalid':
                    submitted && $v.globalContractInfosForm.contractRef.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted && $v.globalContractInfosForm.contractRef.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.globalContractInfosForm.contractRef.required"
                  >La référence du contrat est obligatoire.</span
                >
                <br />
                <span v-if="!$v.globalContractInfosForm.contractRef.maxLength"
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.contractRef.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Désignation *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractDesignation"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.contractDesignation.$touch"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.contractDesignation.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.contractDesignation.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="
                    !$v.globalContractInfosForm.contractDesignation.required
                  "
                  >La Désignation du contrat est obligatoire.</span
                >
                <br />
                <span
                  v-if="
                    !$v.globalContractInfosForm.contractDesignation.maxLength
                  "
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.contractDesignation.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Objet *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractObject"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.contractObject.$touch"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.contractObject.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted && $v.globalContractInfosForm.contractObject.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.globalContractInfosForm.contractObject.required"
                  >L'objet du contrat est obligatoire.</span
                >
                <br />
                <span
                  v-if="!$v.globalContractInfosForm.contractObject.maxLength"
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.contractObject.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div class="form-group">
              <label for="formrow-inputCity">Type de Prestation *</label>
              <multiselect
                    :searchable="true"
                    v-model="globalContractInfosForm.contractType"
                    track-by="id"
                    label="designation"
                    :options="contractTypes"
                    placeholder="Select Contract Type"
                    :select-label="''"
                    :allow-empty="false"
                    :class="{
                      'is-invalid': submitted && $v.globalContractInfosForm.contractType.$error,
                      'disabledMS': formDisabled,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
              
              <div
                v-if="
                  submitted && $v.globalContractInfosForm.contractType.$error
                "
                class="invalid-feedback"
              >
                <span v-if="!$v.globalContractInfosForm.contractType.required"
                  >Le Type est obligatoire.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-password-input">Prestataire *</label>
              <multiselect
                    :searchable="true"
                    v-model="globalContractInfosForm.contractSupplier"
                    track-by="id"
                    label="name"
                    :options="suppliers"
                    :select-label="''"
                    placeholder="Select Supplier"
                    :allow-empty="false"
                    :class="{
                      'is-invalid': submitted && $v.globalContractInfosForm.contractSupplier.$error,
                      'disabledMS': formDisabled,
                    }"
                  >
                    <template slot="singleType" slot-scope="{ supplier }">{{
                      supplier.name
                    }}</template>
                  </multiselect>
           
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.contractSupplier.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.globalContractInfosForm.contractSupplier.required"
                  >Le prestataire est obligatoire.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Attachement <a v-if="globalContractInfosForm.ov_file.fullPath != ''" :href="globalContractInfosForm.ov_file.fullPath" target="_blank"><i class="fas fa-download"></i></a></label>
              <vue-dropzone
                id="dropzone"
                v-model="invoiceAttachment"
                class="ebs-upload"
                ref="myVueDropzone"
                :use-custom-slot="true"
                :options="dropzoneOptions"
                @vdropzone-complete="afterComplete"
              >
                <div class="dropzone-custom-content">
                  <i class="display-4 text-muted fas fa-upload"></i>
                </div>
              </vue-dropzone>
              
            </div>
          </div>
        </div>

        <div class="row">
          
          <div class="col-lg-2">
            <div class="form-group">
              <label for="formrow-inputCity">Date début de validité *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractPeriodFrom"
                type="date"
                class="form-control"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.contractPeriodFrom.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.contractPeriodFrom.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.globalContractInfosForm.contractPeriodFrom.required"
                  >La date début du contrat est obligatoire.</span
                >
              </div>
            </div>
          </div>
          <div class="col-lg-2">
            <div class="form-group">
              <label for="formrow-inputState">Date d'expiration *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractPeriodTo"
                type="date"
                class="form-control"
                placeholder=""
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.contractPeriodTo.$error,
                }"
              />
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.contractPeriodTo.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.globalContractInfosForm.contractPeriodTo.required"
                  >La date fin du contrat est obligatoire.</span
                >
              </div>
            </div>
          </div>

          <div class="col-lg-2">
            <div class="form-group">
              <label for="formrow-inputCity">Date de signature</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractSignatureDate"
                type="date"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>
          <div class="col-lg-2">
            <div class="form-group">
              <label for="formrow-inputState">Date d’entrée en vigueur</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.contractEffectiveDate"
                type="date"
                class="form-control"
                placeholder=""
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Domiciliation Client *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.clientDomiciliation"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.clientDomiciliation.$touch"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.clientDomiciliation.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.clientDomiciliation.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="
                    !$v.globalContractInfosForm.clientDomiciliation.required
                  "
                  >La Domiciliation Client est obligatoire.</span
                >
                <br />
                <span
                  v-if="
                    !$v.globalContractInfosForm.clientDomiciliation.maxLength
                  "
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.clientDomiciliation.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input">Représentant Client *</label>
              <input
                id="projectname"
                v-model="globalContractInfosForm.clientRepresentative"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.clientRepresentative.$touch"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.clientRepresentative.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.clientRepresentative.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="
                    !$v.globalContractInfosForm.clientRepresentative.required
                  "
                  >Le Représentant Client est obligatoire.</span
                >
                <br />
                <span
                  v-if="
                    !$v.globalContractInfosForm.clientRepresentative.maxLength
                  "
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.clientRepresentative.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input"
                >Domiciliation Prestataire *</label
              >
              <input
                id="projectname"
                v-model="globalContractInfosForm.spDomiciliation"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.spDomiciliation.$touch"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.spDomiciliation.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted && $v.globalContractInfosForm.spDomiciliation.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.globalContractInfosForm.spDomiciliation.required"
                  >La Domiciliation Prestataire est obligatoire.</span
                >
                <br />
                <span
                  v-if="!$v.globalContractInfosForm.spDomiciliation.maxLength"
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.spDomiciliation.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label for="formrow-email-input"
                >Représentant Prestataire *</label
              >
              <input
                id="projectname"
                v-model="globalContractInfosForm.spRepresentative"
                type="text"
                class="form-control"
                @blur="$v.globalContractInfosForm.spRepresentative.$touch"
                :class="{
                  'is-invalid':
                    submitted &&
                    $v.globalContractInfosForm.spRepresentative.$error,
                }"
                placeholder=""
              />
              <div
                v-if="
                  submitted &&
                    $v.globalContractInfosForm.spRepresentative.$error
                "
                class="invalid-feedback"
              >
                <span
                  v-if="!$v.globalContractInfosForm.spRepresentative.required"
                  >Représentant Prestataire est obligatoire.</span
                >
                <br />
                <span
                  v-if="!$v.globalContractInfosForm.spRepresentative.maxLength"
                  >La taille minimum du champs est:
                  <b>{{ this.vrules.spRepresentative.maxLength }}</b>
                  Caractères.</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-12">
            <label for="formrow-inputState">Comentaires</label>
            <textarea
              v-model="globalContractInfosForm.contractComments"
              class="form-control"
              cols="30"
              rows="1"
            ></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button
              v-if="showValidationBtn"
              type="button"
              @click="validateGeneralInfosClick"
              class="btn btn-label btn-success float-right"
            >
              <i class="bx bx-check-double label-icon"></i> Valider l'étape
            </button>
            <b-button v-if="showValidationBtn"  @click="cancelForm" variant="danger" class="btn btn-light btn-label  mr-2 float-right">
                <i class="fas fa-times-circle text-white label-icon"></i> Annuler
            </b-button>
          </div>
        </div>
      </div>
    </div>
    </fieldset>
  </div>
</template>

<script>
import {
  required,
  decimal,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import {diffInDays} from "@/helpers/functions";
import Multiselect from "vue-multiselect";
import { mapGetters } from 'vuex';

export default {
  components: { Multiselect , vueDropzone: vue2Dropzone },
  import: {diffInDays},
  props: {
    suppliers: {},
    contract: {
      type: Object,
      default: null,
    },
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
    formDisabled: {
      type: Boolean,
      default: true,
    },
    contractTypes: {},
  },
  computed: {
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn(){
      var show = true;
      if(this.contract){
        show = this.contract.statusPlain == 'draft' || this.user.role.id == 1;
      }
      return show;
    },
    validationErrors(){
        let errors = Object.values(this.errors);
        errors = errors.flat();
        return errors;
    },
    
  },
  watch: {
    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.submitted = false;
        this.clearForm();
      }
    },
    contract(){
      if(this.contract){
        this.globalContractInfosForm.contractRef = this.contract.ref;
        this.globalContractInfosForm.contractDesignation = this.contract.designation;
        this.globalContractInfosForm.contractObject = this.contract.object;
        this.globalContractInfosForm.contractType = this.contract.typeObj;
        this.globalContractInfosForm.contractSupplier = this.contract.supplier;
        this.globalContractInfosForm.contractPeriodFrom = this.contract.start_date;
        this.globalContractInfosForm.contractPeriodTo = this.contract.end_date;
        this.globalContractInfosForm.contractSignatureDate = this.contract.contract_signature_date;
        this.globalContractInfosForm.contractEffectiveDate = this.contract.contract_effective_date;
        this.globalContractInfosForm.contractComments = this.contract.description;
        this.globalContractInfosForm.clientDomiciliation = this.contract.client_domiciliation;
        this.globalContractInfosForm.clientRepresentative = this.contract.client_representative;
        this.globalContractInfosForm.spDomiciliation = this.contract.sp_domiciliation;
        this.globalContractInfosForm.spRepresentative = this.contract.sp_representative;        
        this.globalContractInfosForm.isUpdate = true;        
        this.globalContractInfosForm.contractId = this.contract.id;        
        this.globalContractInfosForm.id = this.contract.id;        
        this.globalContractInfosForm.ov_file = this.contract.ov_file;       
        this.globalContractInfosForm.uuid = this.contract.uuid;       
        this.formDisabled = this.contract.statusPlain != 'draft'; 
      }else{
        this.clearForm();
      }
    },

    suppliers(){
      
    }
  },
  methods: {
    afterComplete(file) {
      var res = JSON.parse(file.xhr.response);
      this.globalContractInfosForm.attachment_path = res.original.uploadedFile.uuid;
    },
    cancelForm(){
      this.$emit('cancelCurrentForm', true);
    },
    clearForm(){
        this.globalContractInfosForm.contractRef = "";
        this.globalContractInfosForm.contractDesignation = "";
        this.globalContractInfosForm.contractObject = "";
        this.globalContractInfosForm.contractType = "";
        this.globalContractInfosForm.contractSupplier = "";
        this.globalContractInfosForm.contractPeriodFrom = "";
        this.globalContractInfosForm.contractPeriodTo = "";
        this.globalContractInfosForm.contractSignatureDate = "";
        this.globalContractInfosForm.contractEffectiveDate = "";
        this.globalContractInfosForm.contractComments = "";
        this.globalContractInfosForm.clientDomiciliation = "";
        this.globalContractInfosForm.clientRepresentative = "";
        this.globalContractInfosForm.spDomiciliation = "";
        this.globalContractInfosForm.spRepresentative = "";
        this.globalContractInfosForm.ov_file = "";
    },

    _check_dates(){
      var valid = true;
      var days = diffInDays(this.globalContractInfosForm.contractPeriodFrom, this.globalContractInfosForm.contractPeriodTo);

      var signatureVsContractFrom = diffInDays(this.globalContractInfosForm.contractPeriodFrom, this.globalContractInfosForm.contractSignatureDate);

      // var signatureVsEffectiveDate = diffInDays(this.globalContractInfosForm.contractSignatureDate, this.globalContractInfosForm.contractEffectiveDate);

      var expirationVsEffectiveDate = diffInDays(this.globalContractInfosForm.contractEffectiveDate, this.globalContractInfosForm.contractPeriodTo);
      
      var expirationVsSignatureDate = diffInDays(this.globalContractInfosForm.contractSignatureDate, this.globalContractInfosForm.contractPeriodTo);

      if(days < 1){
        valid = false;
        this.$toast.error("Veuillez vérifier la Date début de validité et la Date d'expiration du contrat");
      }
      if(signatureVsContractFrom < 0 ){ // -1 it's the same date
        valid = false;
        this.$toast.error("La date de signature du contrat doit être <strong>Postèrieure</strong> à la date de début du contrat");
      }

      if(expirationVsSignatureDate < 0){
        valid = false;
        this.$toast.error("La date de signature du contrat doit être <strong>Aniétieure</strong> à la date de fin du contrat");
      }

      // if(signatureVsEffectiveDate < 0){
      //   valid = false;
      //   this.$toast.error("La date d'entrée en vigeure du contrat doit être <strong>Postèrieure</strong> à la date de signature");
      // }

      if(expirationVsEffectiveDate < 0){
        valid = false;
        this.$toast.error("La date d'entrée en vigeure du contrat doit être <strong>Aniétieure</strong> à la date de fin du contrat");
      }

      

      return valid;
    },

    validateGeneralInfosClick() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (!this.$v.$invalid && this._check_dates()) {
        let loader = this.$loading.show({
          container: this.$refs.generalInfosContainer,
        });
        this.$http
          .post(
            "/contracts/contracts/saveGeneralInfoStep",
            this.globalContractInfosForm
          )

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.savedContract;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.contract = contract;
                this.$emit('GeneralInfoStepSaved', contract);
                break;

              case 500:
                loader.hide();
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            console.log(error);
            if(error.response.status == 422){
              this.errors = error.response.data.errors;
              this.$toast.error(error.response.data.message);
            }else{
              this.$toast.error(error.message);
            }
          })
          .finally(() => {});
      }
    },

    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: process.env.VUE_APP_BASE_URL + "system/upload/processFileUpload",
        params: {
          attachmentType: "contract",
          modalId: 1,
        },
        thumbnailWidth: 150,
        maxFilesize: 10,
        maxFiles: 1,
        // autoProcessQueue : false,
        addRemoveLinks: true,
        acceptedFiles: ".pdf,.doc,.docx,.xls,.xlsx",
      },
      invoiceAttachment: false,
      errors: [],
      isLoading: true,
      fullPage: true,
      busy: false,
      title: "Contrats",
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],
      globalContractInfosForm: {
        contractRef: "",
        contractDesignation: "",
        contractObject: "",
        contractSupplier: "",
        contractType: "",
        contractPeriodFrom: "",
        contractPeriodTo: "",
        contractAmnt: "",
        contractComments: "",
        attachment_path : "",
        ov_file :{
          fullPath : '',
          name : '',
        },
        clientDomiciliation: "",
        clientRepresentative: "",
        spDomiciliation: "",
        spRepresentative: "",
        // catering contract
        contractAllocatedHostingAmmount: "",
        contractAllocatedCateringAmmount: "",
        contractAllocated3dAmmount: "",
        // gardenig contract
        contractPesticidesAmountAllocated: "",
        contractConstructionsAmountAllocated: "",
        contractRollingEquipmentsAmountAllocated: "",
        contractMaintenanceAmountAllocated: "",
        // other contract
        contractSignatureDate: "",
        contractEffectiveDate: "",
      },
      types: [
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },

  validations: {
    globalContractInfosForm: {
      contractRef: {
        required,
        maxLength: maxLength(64),
      },
      contractDesignation: {
        required,
        maxLength: maxLength(254),
      },
      contractObject: {
        required,
        maxLength: maxLength(254),
      },

      clientDomiciliation: {
        required,
        maxLength: maxLength(254),
      },
      clientRepresentative: {
        required,
        maxLength: maxLength(254),
      },
      spDomiciliation: {
        required,
        maxLength: maxLength(254),
      },
      spRepresentative: {
        required,
        maxLength: maxLength(254),
      },
      contractSupplier: { required },
      contractType: { required },
      contractPeriodFrom: { required },
      contractPeriodTo: { required },
    },
  },
};
</script>
<style >
  .dropzone {
    min-height: 5px;
    padding: 0px;
  }
</style>