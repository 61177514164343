<template>
  <div ref="newServiceModal">
    <b-tab active class="border-0">
      <template v-slot:title>
        <span class="d-inline-block d-sm-none">
          <i class="fas fa-home"></i>
        </span>
        <span class="d-none d-sm-inline-block">
          Restauration
        </span>
      </template>
      <!-- PRICE CHECK -->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group">
            <label for="">Montant alloué</label>
            <a href="" style="float:right;">Enregistrer</a>
            <input
              type="text"
              style="display:inline-block"
              class="form-control"
              v-model="allocatedCateringAmnt"
              name=""
              id=""
            />
          </div>
        </div>
      </div>
      <price-check
        :allocatedAmnt="contract.amounts.catering_amount.plain"
        :items="data"
      />

      <dataset v-slot="{ ds }" :ds-data="data">
        <div class="row">
          <div class="col-md-4">
            <label for="">Type de repas</label>
            <multiselect
              :searchable="true"
              v-model="restSearchForm.mealType"
              track-by="designation"
              label="designation"
              :select-label="''"
              :options="restSearchData.mealTypes"
              placeholder="Select Type de repas"
              @input="filterDataWithMealType"
              :allow-empty="true"
            >
              <template slot="singleType" slot-scope="{ type }">{{
                type.designation
              }}</template>
            </multiselect>
          </div>

          <div class="col-md-4">
            <button class="btn btn-warning mt-4" @click="resetSearchForm">
              Vider
            </button>
          </div>

          <div class="col-md-4">
            <download-excel
              :fields="excel_header"
              style="cursor:pointer"
              class="float-right"
              worksheet="Prestation Restauration"
              name="PrestationRestauration.xls"
              :data="data"
            >
              <img
                width="40"
                :src="require('@/assets/images/base/excel.png')"
              />
            </download-excel>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="">
              <table class="table table-hover d-md-table">
                <thead slot="head">
                  <th>Type repas</th>
                  <th>Période</th>
                  <th>Lieu</th>
                  <th>Service</th>
                  <th>Désignation</th>
                  <th>Unité de mesure</th>
                  <!-- <th>Type de contrôl</th> -->
                  <th>Prix unitaire</th>
                  <th>Qté. Prévision</th>
                  <!-- <th>Type Prévision</th> -->
                  <!-- <th>Qté. Annuelle</th> -->
                  <th>Qté. Contrat</th>
                  <th>Actions</th>
                </thead>
                <dataset-item tag="tbody">
                  <template #default="{row}">
                    <tr>
                      <td>{{ row.mealType }}</td>
                      <td>{{ row.mealPeriod }}</td>
                      <td>{{ row.mealLocation }}</td>
                      <td>{{ row.mealService }}</td>
                      <td>{{ row.name }}</td>
                      <td>
                        <span class="badge badge-primary">{{ row.uom }}</span>
                      </td>
                      <!-- <td>
                        <span class="badge badge-info">{{
                          row.control_type.name
                        }}</span>
                      </td> -->
                      <td>{{ row.unit_price.withCurrency }}</td>
                      <td>{{ row.estimated_qty.formated }}</td>
                      <!-- <td>
                        <span class="badge badge-info">{{
                          row.estimation_type.name
                        }}</span>
                      </td> -->
                      <!-- <td>{{ row.yearly_qty.formated }}</td> -->
                      <td>{{ row.contractual_qty.formated }}</td>
                      <td>
                        <a
                          href="javascript:void(0)"
                          v-if="showValidationBtn"
                          @click="editSingleService(row.id)"
                          ><i class="fas fa-edit"></i
                        ></a>
                        <a
                          href="javascript:void(0)"
                          v-if="showValidationBtn"
                          @click="deleteServiceFromContract(row.name, row.id)"
                          ><i class="fas fa-trash text-danger"></i
                        ></a>
                        <a
                          href="javascript:void(0)"
                          @click="editSingleService(row.id)"
                          v-else
                        >
                          <i class="fas fa-eye"></i>
                        </a>
                      </td>
                    </tr>
                  </template>
                </dataset-item>
              </table>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-md-row flex-column justify-content-between align-items-center"
        >
          <dataset-show :ds-show-entries="5" />
          <dataset-pager />
        </div>
      </dataset>
      <div class="row">
        <div class="col-md-12">
          <h5>
            <a
              v-if="showValidationBtn"
              @click.prevent="addNewRestServiceClick"
              href="javascript:void(0)"
              class=""
              ><i class="fas fa-plus-circle"></i> Ajouter</a
            >
          </h5>
        </div>
      </div>
    </b-tab>

    <Modal
      v-model="showNewRestaurationService"
      title="Ajouter une prestation de restauration"
      ref="newRestaurationServiceModal"
      @close="_clearNewServiceForm"
    >
      <form @submit.prevent="saveNewServiceToContract">
        <fieldset :disabled="newServiceModal.readMode">
          <div class="row">
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Type de repas *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.mealType"
                      track-by="id"
                      label="designation"
                      :select-label="''"
                      :options="newServiceModal.mealsTypesList"
                      placeholder="Select Type de repas"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.mealType.$error,
                      }"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.designation
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted && $v.newServiceModal.formData.mealType.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.mealType.required"
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Période repas *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.mealPeriod"
                      track-by="id"
                      :select-label="''"
                      label="designation"
                      :options="newServiceModal.mealsPeriodsList"
                      placeholder="Select Période repas"
                      :allow-empty="false"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.mealPeriod.$error,
                      }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.designation
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.mealPeriod.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.mealPeriod.required"
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Lieu repas *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.mealLocation"
                      track-by="id"
                      label="designation"
                      :select-label="''"
                      :options="newServiceModal.mealsLocationsList"
                      placeholder="Select Lieu repas"
                      :allow-empty="false"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.mealLocation.$error,
                      }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.designation
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.mealLocation.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.mealLocation.required
                        "
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Service repas *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.mealService"
                      track-by="id"
                      label="designation"
                      :select-label="''"
                      :options="newServiceModal.mealsServicesList"
                      placeholder="Select Service repas"
                      :allow-empty="false"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.mealService.$error,
                      }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.designation
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.mealService.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.mealService.required"
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-8"
                  v-if="
                    newServiceModal.formData.mealType.designation !=
                      'Dotation sur demande'
                  "
                >
                  <div class="form-group">
                    <label for="formrow-email-input">Désignation *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.name"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.newServiceModal.formData.name.$error,
                      }"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted && $v.newServiceModal.formData.name.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.newServiceModal.formData.name.required"
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div
                  class="col-md-4"
                  v-if="
                    newServiceModal.formData.mealType.designation ==
                      'Dotation sur demande'
                  "
                >
                  <div class="form-group">
                    <label for="formrow-email-input">Catégorie *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.productCategId"
                      track-by="id"
                      :select-label="''"
                      label="name"
                      :options="newServiceModal.productCategsList"
                      placeholder="Select Product category"
                      @input="loadProductsViaCategory"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.productCategId.$error,
                      }"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.productCategId.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.productCategId.required
                        "
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-4"
                  v-if="
                    newServiceModal.formData.mealType.designation ==
                      'Dotation sur demande'
                  "
                >
                  <div class="form-group">
                    <label for="formrow-email-input">Produit *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.productId"
                      track-by="id"
                      :select-label="''"
                      label="name"
                      :options="newServiceModal.productsList"
                      placeholder="Select Product"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.productId.$error,
                      }"
                      :allow-empty="false"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.productId.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.productId.required"
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Unité de mesure *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.Uom"
                      track-by="id"
                      :select-label="''"
                      label="designation"
                      :options="newServiceModal.UomsList"
                      placeholder="Select Unité de mesure"
                      :allow-empty="false"
                      :class="{
                        'is-invalid':
                          submitted && $v.newServiceModal.formData.Uom.$error,
                      }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.designation
                      }}</template>
                    </multiselect>
                    <div
                      v-if="submitted && $v.newServiceModal.formData.Uom.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.newServiceModal.formData.Uom.required"
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Type contrôle *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.contractControlType"
                      track-by="id"
                      label="name"
                      :select-label="''"
                      :options="newServiceModal.contractControlTypesList"
                      placeholder="Select Type contrôle"
                      :allow-empty="false"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.contractControlType
                            .$error,
                      }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.contractControlType.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.contractControlType
                            .required
                        "
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label for="formrow-email-input">Type prévision *</label>
                    <multiselect
                      :searchable="true"
                      v-model="newServiceModal.formData.contractEstimationType"
                      track-by="id"
                      label="name"
                      :select-label="''"
                      :options="newServiceModal.contractEstimationTypesList"
                      placeholder="Select Type prévision"
                      :allow-empty="false"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.contractEstimationType
                            .$error,
                      }"
                    >
                      <template slot="singleType" slot-scope="{ type }">{{
                        type.name
                      }}</template>
                    </multiselect>
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.contractEstimationType
                            .$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.contractEstimationType
                            .required
                        "
                        >Champs obligatoire.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="formrow-email-input">Prix Unitaire *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.unitPrice"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.unitPrice.$error,
                      }"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.unitPrice.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.unitPrice.required"
                        >Champs obligatoire.</span
                      >
                      <span
                        v-if="!$v.newServiceModal.formData.unitPrice.decimal"
                        >Champs Invalide.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="formrow-email-input">Qté. Prévision *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.estimatedQty"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.estimatedQty.$error,
                      }"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.estimatedQty.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.estimatedQty.required
                        "
                        >Champs obligatoire.</span
                      >
                      <span
                        v-if="!$v.newServiceModal.formData.estimatedQty.decimal"
                        >Champs Invalide.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="formrow-email-input">Qté. Annuelle *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.annualQty"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.annualQty.$error,
                      }"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.annualQty.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.annualQty.required"
                        >Champs obligatoire.</span
                      >
                      <span
                        v-if="!$v.newServiceModal.formData.annualQty.decimal"
                        >Champs Invalide.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="formrow-email-input">Qté. Contrat *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.contractualQty"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.contractualQty.$error,
                      }"
                      class="form-control"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted &&
                          $v.newServiceModal.formData.contractualQty.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.contractualQty.required
                        "
                        >Champs obligatoire.</span
                      >
                      <span
                        v-if="
                          !$v.newServiceModal.formData.contractualQty.decimal
                        "
                        >Champs Invalide.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="formrow-email-input">Qté Min *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.minQty"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.minQty.$error,
                      }"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted && $v.newServiceModal.formData.minQty.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.newServiceModal.formData.minQty.required"
                        >Champs obligatoire.</span
                      >
                      <span v-if="!$v.newServiceModal.formData.minQty.decimal"
                        >Champs Invalide.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label for="formrow-email-input">Unité d'oeuvre *</label>
                    <input
                      type="text"
                      v-model="newServiceModal.formData.workUnit"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted &&
                          $v.newServiceModal.formData.workUnit.$error,
                      }"
                      name=""
                      id=""
                    />
                    <div
                      v-if="
                        submitted && $v.newServiceModal.formData.workUnit.$error
                      "
                      class="invalid-feedback"
                    >
                      <span
                        v-if="!$v.newServiceModal.formData.workUnit.required"
                        >Champs obligatoire.</span
                      >
                      <span v-if="!$v.newServiceModal.formData.workUnit.decimal"
                        >Champs Invalide.</span
                      >
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <div v-if="!newServiceModal.readMode" class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import priceCheck from "./components/priceCheck";
import Swal from "sweetalert2";
import Dataset from "vue-dataset/dist/es/Dataset.js";
import DatasetItem from "vue-dataset/dist/es/DatasetItem.js";
import DatasetPager from "vue-dataset/dist/es/DatasetPager.js";
import DatasetShow from "vue-dataset/dist/es/DatasetShow.js";
import {
  getDropDownElementsByTypeApi,
  fetchProductsCategoriesListApi,
  fetchProductsWithCategoryListApi,
} from "@/api/common";
import { required, requiredIf, decimal } from "vuelidate/lib/validators";
export default {
  components: {
    Multiselect,
    priceCheck,
    Dataset,
    DatasetItem,
    DatasetPager,
    DatasetShow,
  },
  import: {
    getDropDownElementsByTypeApi,
    fetchProductsCategoriesListApi,
    fetchProductsWithCategoryListApi,
  },
  props: {
    contract: {},
    camp: {},
    service: {},
  },
  computed: {
    showValidationBtn() {
      var show = true;
      if (this.contract) {
        show = this.contract.statusPlain == "draft";
      }
      return show;
    },
    formDisabled() {
      var disabled = false;
      if (this.contract) {
        disabled = this.contract.statusPlain != "draft";
      }
      return disabled;
    },
  },
  data() {
    return {
      isModalLoading: false,
      data: [],
      dataBkp: [],
      submitted: false,
      excel_header: {
        "Type repas": "mealType",
        Période: "mealPeriod",
        Lieu: "mealLocation",
        Service: "mealService",
        Désignation: "name",
        "Unité de mesure": "uom",
        "Type de contrôl": "control_type.name",
        "Prix unitaire": "unit_price.withCurrency",
        "Qté. Prévision": "estimated_qty.formated",
        "Type Prévision": "estimation_type.name",
        "Qté. Annuelle": "yearly_qty.formated",
        "Qté. Contrat": "contractual_qty.formated",
      },

      allocatedCateringAmnt: "",

      newServiceModal: {
        readMode: false,
        mealsTypesList: [],
        mealsPeriodsList: [],
        mealsLocationsList: [],
        mealsServicesList: [],
        productCategsList: [],
        productsList: [],
        UomsList: [],
        contractControlTypesList: [],
        contractEstimationTypesList: [],
        formData: {
          name: "",
          mealType: "",
          mealPeriod: "",
          mealLocation: "",
          mealService: "",
          Uom: "",
          contractControlType: "",
          contractEstimationType: "",
          annualQty: "",
          contractualQty: "",
          unitPrice: "",
          estimatedQty: "",
          service: "",
          productCategId: "",
          productId: "",
          minQty: "",
          workUnit: "",
        },
      },

      restSearchForm: {
        mealType: "",
      },

      restSearchData: {
        mealTypes: [],
        mealPeriods: [],
        mealLocations: [],
        mealServices: [],
      },
      showNewRestaurationService: false,
    };
  },

  watch: {
    contract() {},

    camp() {},

    showNewRestaurationService() {
      if (this.showNewRestaurationService) {
        this.loadNewServiceModalData();
      }
    },
  },

  mounted() {
    this.reloadContractServices();
    this.loadRestaurationSearchData();
  },

  methods: {
    reloadContractServices() {
      var campId = this.camp.id;
      var contractId = this.contract.id;
      var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/getContractServicesInCamp", {
          campId: campId,
          contractId: contractId,
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var services = res.data.cateringServices;
          var allocatedCateringAmnt = res.data.cateringAllocatedAmount;
          switch (status) {
            case 200:
              this.allocatedCateringAmnt = allocatedCateringAmnt.plain;
              this.data = services;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          // loader.hide();:
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    filterDataWithMealType() {
      var mealTypeName = this.restSearchForm.mealType.designation;
      var filteredList = [];
      if (this.dataBkp.length > 0) {
        // recover old data
        this.data = this.dataBkp;
      }
      this.data.forEach((element) => {
        if (element.mealType == mealTypeName) {
          filteredList.push(element);
        }
      });
      this.dataBkp = this.data;
      this.data = filteredList;
    },

    resetSearchForm() {
      this.data = this.dataBkp;
      this.restSearchForm.mealType = "";
    },

    loadRestaurationSearchData() {
      var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/loadRestaurationSearchData")
        .then((res) => {
          loader.hide();
          this.restSearchData.mealTypes = res.data.mealTypes;
          this.restSearchData.mealPeriods = res.data.mealPeriods;
          this.restSearchData.mealLocations = res.data.mealLocations;
          this.restSearchData.mealServices = res.data.mealServices;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    addNewRestServiceClick() {
      this.showNewRestaurationService = true;
      // this.loadNewServiceModalData();
    },

    loadProductsViaCategory() {
      fetchProductsWithCategoryListApi(
        this.newServiceModal.formData.productCategId.id
      )
        .then((res) => {
          this.newServiceModal.productsList = res.data.products;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    loadNewServiceModalData() {
      // var loader = this.$loading.show();
      this.fetchBaseData();
      this.fetchProductsCategories();
      // loader.hide();
    },
    fetchProductsCategories() {
      fetchProductsCategoriesListApi()
        .then((res) => {
          this.newServiceModal.productCategsList = res.data.data;
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },
    fetchBaseData() {
      var loader = this.$loading.show();
      this.$http
        .post("/contracts/contracts/fetchBaseData")
        .then((res) => {
          loader.hide();
          this.newServiceModal.mealsTypesList = res.data.mealTypes;
          this.newServiceModal.UomsList = res.data.uoms;
          this.newServiceModal.mealsServicesList = res.data.mealServices;
          this.newServiceModal.mealsLocationsList = res.data.mealLocations;
          this.newServiceModal.mealsPeriodsList = res.data.mealPeriods;
          this.newServiceModal.contractControlTypesList = res.data.controlTypes;
          this.newServiceModal.contractEstimationTypesList =
            res.data.estimationTypes;
        })
        .catch(() => {
          loader.hide();
          // error.response.status Check status code
        })
        .finally(() => {
          loader.hide();
          //Perform action in always
        });
    },

    _clearNewServiceForm() {
      this.submitted = false;
      this.newServiceModal.formData.name = "";
      this.newServiceModal.formData.mealType = "";
      this.newServiceModal.formData.mealPeriod = "";
      this.newServiceModal.formData.mealLocation = "";
      this.newServiceModal.formData.mealService = "";
      this.newServiceModal.formData.Uom = "";
      this.newServiceModal.formData.contractControlType = "";
      this.newServiceModal.formData.contractEstimationType = "";
      this.newServiceModal.formData.annualQty = "";
      this.newServiceModal.formData.contractualQty = "";
      this.newServiceModal.formData.unitPrice = "";
      this.newServiceModal.formData.estimatedQty = "";
      this.newServiceModal.formData.service = "";
      this.newServiceModal.formData.productCategId = "";
      this.newServiceModal.formData.productId = "";
      this.newServiceModal.formData.minQty = "";
      this.newServiceModal.formData.workUnit = "";
      this.newServiceModal.formData.isUpdate = false;
      this.newServiceModal.formData.servId = null;
      this.newServiceModal.readMode = false;
    },

    saveNewServiceToContract() {
      // send data to api
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.newServiceModal.formData.service = this.service;
        this.newServiceModal.formData.contract = this.contract;
        this.newServiceModal.formData.camp = this.camp;
        var loader = this.$loading.show();
        this.$http
          .post(
            "/contracts/contracts/saveUnitPriceData",
            this.newServiceModal.formData
          )
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this._clearNewServiceForm();
                this.showNewRestaurationService = false;
                this.$emit("reloadContractServicesStatus", true);
                this.reloadContractServices();
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
      // if (true) {

      // }
    },

    editSingleService(servId) {
      // get service details
      var loader = this.$loading.show({ zIndex: 1700 });
      this.$http
        .post("/contracts/contracts/getSingleServiceDetails", {
          servId: servId,
          service: "catering",
        })
        .then((res) => {
          loader.hide();
          var status = res.data.status;
          var service = res.data.service;
          switch (status) {
            case 200:
              // this.$toast.success(res.data.original.msg);
              this.newServiceModal.formData.name = service.name;
              this.newServiceModal.formData.annualQty =
                service.yearly_qty.plain;
              this.newServiceModal.formData.contractualQty =
                service.contractual_qty.plain;
              this.newServiceModal.formData.unitPrice =
                service.unit_price.plain;
              this.newServiceModal.formData.estimatedQty =
                service.estimated_qty.plain;

              this.newServiceModal.formData.mealType = service.mealTypeObj;
              this.newServiceModal.formData.mealPeriod = service.mealPeriodObj;
              this.newServiceModal.formData.mealLocation =
                service.mealLocationObj;
              this.newServiceModal.formData.mealService =
                service.mealServiceObj;
              this.newServiceModal.formData.Uom = service.uomObj;

              this.newServiceModal.formData.contractControlType =
                service.control_type;
              this.newServiceModal.formData.contractEstimationType =
                service.estimation_type;

              this.newServiceModal.formData.minQty = service.default_qty;
              this.newServiceModal.formData.workUnit =
                service.internal_work_unit;

              if (service.product) {
                this.newServiceModal.formData.productCategId =
                  service.product.category;
                this.newServiceModal.formData.productId = service.product;
              }

              this.newServiceModal.formData.isUpdate = true;
              this.newServiceModal.formData.servId = service.id;

              this.showNewRestaurationService = true;
              this.newServiceModal.readMode = !this.showValidationBtn;
              break;

            case 500:
              this.$toast.warning(res.data.original.msg);
              break;
          }
        })
        .catch((error) => {
          loader.hide();
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    deleteServiceFromContract(servName, servId) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer Le service: '" + servName + "' ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show({ zIndex: 1700 });
          this.$http
            .post("/contracts/contracts/deleteServiceFromContract", {
              servId: servId,
              contract: this.contract.id,
              camp: this.camp.id,
              type: "catering",
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit("reloadContractServicesStatus", true);
                  this.reloadContractServices();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
  },

  validations: {
    newServiceModal: {
      formData: {
        name: {
          required: requiredIf(function() {
            return (
              this.newServiceModal.formData.mealType.designation !=
              "Dotation sur demande"
            );
          }),
        },
        annualQty: { required, decimal },
        contractualQty: { required, decimal },
        unitPrice: { required, decimal },
        estimatedQty: { required, decimal },
        minQty: { required, decimal },
        workUnit: { required, decimal },
        mealType: { required },
        mealPeriod: { required },
        mealLocation: { required },
        mealService: { required },
        Uom: { required },
        contractControlType: { required },
        contractEstimationType: { required },
        productId: {
          required: requiredIf(function() {
            return (
              this.newServiceModal.formData.mealType.designation ==
              "Dotation sur demande"
            );
          }),
        },
        productCategId: {
          required: requiredIf(function() {
            return (
              this.newServiceModal.formData.mealType.designation ==
              "Dotation sur demande"
            );
          }),
        },
      },
    },
  },
};
</script>
