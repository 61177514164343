<template>
  <div>
    <div class="row">
      <div class="card-body">
        <div class="row">
          <template v-if="contract">
            <div class="col-md-4" v-for="base in contract.selectedCampsWithServices" v-bind:key="base.id">
              <b-card
                :title="base.name"
                tag="article"
                style="border: 2px solid blue"
                class="mb-2"
              >
                <b-card-text>
                  Configurer les prestations suivantes pour la base de vie:
                  <b>{{ base.name }}</b
                  >:
                  <ol style="height: 100px">
                    <li
                      v-for="service in base.services"
                      v-bind:key="service.id"
                    >
                      {{ service.name }}
                      <i
                        class="fas fa-check-circle text-success bg-soft"
                        v-if="service.setupDone"
                      ></i>
                      <i
                        class="fas fa-exclamation-triangle text-warning bg-soft"
                        v-else
                      ></i>
                    </li>

                    <template v-for="customBase in contract.selectedCampsWithCustomServices">
                      <template v-if="customBase.id == base.id">
                        <li v-for="service in customBase.services" v-bind:key="service.id">
                            {{ service.name }}
                            <i class="fas fa-check-circle text-success bg-soft" v-if="service.setupDone"></i>
                            <i class="fas fa-exclamation-triangle text-warning bg-soft" v-else></i>
                        </li>
                      </template>
                    </template>
                  </ol>
                </b-card-text>

                <b-button
                  href="#"
                  @click="showPrestationsModalClick(base)"
                  :variant="baseSetupDone(base) ? 'success' : 'warning'"
                >
                  <i
                    class="fas fa-exclamation-triangle"
                    v-if="!baseSetupDone(base)"
                  ></i>
                  <i class="far fa-check-circle" v-else></i>
                  Prestations
                </b-button>
              </b-card>
            </div>



            <div class="col-md-4" v-for="customBase in contract.selectedCampsWithCustomServices" v-bind:key="customBase.id">
              <b-card
                :title="customBase.name"
                tag="article"
                style="border: 2px solid blue"
                class="mb-2"
              >
                <b-card-text>
                  Configurer les prestations autres suivantes pour la base de vie:
                  <b>{{ customBase.name }}</b
                  >:
                  <ol style="height: 100px">
                    <li
                      v-for="service in customBase.services"
                      v-bind:key="service.id"
                    >
                      {{ service.name }}
                      <i
                        class="fas fa-check-circle text-success bg-soft"
                        v-if="service.setupDone"
                      ></i>
                      <i
                        class="fas fa-exclamation-triangle text-warning bg-soft"
                        v-else
                      ></i>
                    </li>
                  </ol>
                </b-card-text>

                <b-button
                  href="#"
                  @click="showPrestationsModalClick(customBase)"
                  :variant="baseSetupDone(customBase) ? 'success' : 'warning'"
                >
                  <i
                    class="fas fa-exclamation-triangle"
                    v-if="!baseSetupDone(customBase)"
                  ></i>
                  <i class="far fa-check-circle" v-else></i>
                  Prestations
                </b-button>
              </b-card>
            </div>
          </template>
        </div>

        <div class="row">
          <div class="col-md-12">
            <button
              v-if="showValidationBtn"
              type="button"
              @click="validateUnitPriceScopeClick"
              class="btn btn-label btn-success float-right"
            >
              <i class="bx bx-check-double label-icon"></i> Valider l'étape
            </button>
            <b-button v-if="showValidationBtn"  @click="cancelForm" variant="danger" class="btn btn-light btn-label  mr-2 float-right">
                <i class="fas fa-times-circle text-white label-icon"></i> Annuler
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-model="showLifebaseServicesPricingModal"
      :title="
        'Borderaux des prix unitaires Pour le site: ' +
          this.priceListModalCampName
      "
      modalClass="fullWidthModal"
    >
      <div class="row">
        <div class="col-lg-12">
          <h4 class="card-title">Définitions des prestations & tarrifs</h4>
          <p class="card-title-desc"></p>
          <b-tabs content-class="p-3 text-muted">
            <template v-if="contract">
              <other-service 
              :contract="priceListModalData.contract"
              :camp="priceListModalData.base"
              v-if="contract.hasCustomServices == true"
              @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
              />
            </template>
            
            <restauration-tab
              :contract="priceListModalData.contract"
              :camp="priceListModalData.base"
              :service="'catering'"
              v-if="priceListModalData.allowedTabs.catering"
              @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
            />
            <hosting-tab
              :contract="priceListModalData.contract"
              :camp="priceListModalData.base"
              :service="'hosting'"
              v-if="priceListModalData.allowedTabs.hosting"
              @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
            />
            <maintenance-tab
              v-if="priceListModalData.allowedTabs.maintenance_of_facilities"
              :contract="priceListModalData.contract"
              :camp="priceListModalData.base"
              :service="'maintenance_of_facilities'"
              @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
            />
            <threedops-tab 
              v-if="priceListModalData.allowedTabs.threed_ops" 
              :contract="priceListModalData.contract"
              :camp="priceListModalData.base"
              :service="'threed_ops'"
              @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
            />
            <greenspace-tab 
            v-if="priceListModalData.allowedTabs.greenspace" 
            :contract="priceListModalData.contract"
            :camp="priceListModalData.base"
            :service="'green_space'"
            @reloadContractServicesStatus="handleReloadContractServicesStatusEvent"
            />
            <laundry-tab
              v-if="priceListModalData.allowedTabs.laundry_degreasing"
            />
            <cleaning-tab
              v-if="priceListModalData.allowedTabs.maintenance_cleaning"
            />
            <employees-transport-tab
              v-if="priceListModalData.allowedTabs.personal_transport"
            />
            <cargo-transport-tab
              v-if="priceListModalData.allowedTabs.goods_transport"
            />
          </b-tabs>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style>
.vld-overlay.is-full-page {
  z-index: 9999999 !important;
}
</style>

<script>
import {
  required,
  decimal,
  maxLength,
  requiredIf,
} from "vuelidate/lib/validators";
import RestaurationTab from "./Tabs/UnitPriceSchedule/RestaurationTab";
import HostingTab from "./Tabs/UnitPriceSchedule/HostingTab";
import CleaningTab from "./Tabs/UnitPriceSchedule/CleaningTab";
import EmployeesTransportTab from "./Tabs/UnitPriceSchedule/EmployeesTransportTab";
import GreenspaceTab from "./Tabs/UnitPriceSchedule/GreenspaceTab";
import LaundryTab from "./Tabs/UnitPriceSchedule/LaundryTab";
import MaintenanceTab from "./Tabs/UnitPriceSchedule/MaintenanceTab";
import ThreedopsTab from "./Tabs/UnitPriceSchedule/ThreedopsTab";
import CargoTransportTab from "./Tabs/UnitPriceSchedule/CargoTransportTab";
import otherService from "./Tabs/UnitPriceSchedule/otherService";
import { mapGetters } from 'vuex';
import { VMoney } from "v-money";
export default {
  components: {
    RestaurationTab,
    HostingTab,
    CleaningTab,
    EmployeesTransportTab,
    GreenspaceTab,
    LaundryTab,
    MaintenanceTab,
    ThreedopsTab,
    CargoTransportTab,
    otherService
  },
  props: {
    contractualScope: {},
    selectedCamps: {},
    contract: {},
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    base() {
      return this.priceListModalData.base;
    },
    ...mapGetters({
      authenticated: 'apiAuth/authenticated',
      user: 'apiAuth/user',
    }),
    showValidationBtn() {
      var show = true;
      if (this.contract) {
        show = this.contract.statusPlain == "draft" || this.user.role.id == 1;
      }
      return show;
    },

    formDisabled() {
      var disabled = false;
      if (this.contract) {
        disabled = this.contract.statusPlain != "draft";
      }
      return disabled;
    },
  },

  watch: {
    selectedCamps() {
      
    },

    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.clearForm();
      }
    },

    base() {
      if (this.base) {
        this.priceListModalCampName = this.priceListModalData.base.name;
        this._updateAllowedTabs();
      }
    },

    priceListModalCampName() {
      if (this.priceListModalData.base) {
        this.priceListModalCampName = this.priceListModalData.base.name;
      }
    },
  },

  methods: {

    cancelForm(){
      this.$emit('cancelCurrentForm', true);
    },

    clearForm(){
        this.contract = null;
    },

    handleReloadContractServicesStatusEvent(){
      this.$emit('reloadContractServicesStatus', true);
    },

    baseSetupDone(base) {
      var done = true;
      base.services.forEach((element) => {
        if(!element.setupDone){
          done = false;
        }
      });
      return done;
    },
    _stepChecked(){
      var checked = true;
      var camps = this.contract.selectedCampsWithServices;
      var customServices = this.contract.selectedCampsWithCustomServices;
      camps.forEach(singleCamp => {
        singleCamp.services.forEach(singleService => {
            if(!singleService.setupDone) {
              checked = false;
              this.$toast.error("Vous devez configurer le borderau des prix pour le service: <strong>"+singleService.name+"</strong> Pour le site: <strong>"+singleCamp.name+"</strong>");
            }
        });
      });

      if(customServices){
        customServices.forEach(singleCamp => {
          singleCamp.services.forEach(singleService => {
              if(!singleService.setupDone) {
                checked = false;
                this.$toast.error("Vous devez configurer le borderau des prix pour le service: <strong>"+singleService.name+"</strong> Pour le site: <strong>"+singleCamp.name+"</strong>");
              }
          });
        });
      }

      return checked;
    },
    validateUnitPriceScopeClick() {
      if(this._stepChecked()){
        let loader = this.$loading.show({
          container: this.$refs.finacialInfosContainer,
        });
        this.$http
          .post("/contracts/contracts/validateUnitPriceStep", {
            contractId: this.contract.id,
          })
  
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.savedContract;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this.$emit("UnitPriceScheduleDone", contract);
                break;
  
              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },
    _disallowAllTabs() {
      this.priceListModalData.allowedTabs.hosting = false;
      this.priceListModalData.allowedTabs.catering = false;
      this.priceListModalData.allowedTabs.maintenance_of_facilities = false;
      this.priceListModalData.allowedTabs.laundry_degreasing = false;
      this.priceListModalData.allowedTabs.maintenance_cleaning = false;
      this.priceListModalData.allowedTabs.threed_ops = false;
      this.priceListModalData.allowedTabs.personal_transport = false;
      this.priceListModalData.allowedTabs.goods_transport = false;
      this.priceListModalData.allowedTabs.greenspace = false;
    },
    _updateAllowedTabs() {
      var services = this.base.services;
      this._disallowAllTabs();
      services.forEach((element) => {
        switch (element.id) {
          case "hosting":
            this.priceListModalData.allowedTabs.hosting = true;
            break;

          case "catering":
            this.priceListModalData.allowedTabs.catering = true;
            break;

          case "green_space":
            this.priceListModalData.allowedTabs.greenspace = true;
            break;

          case "maintenance_of_facilities":
            this.priceListModalData.allowedTabs.maintenance_of_facilities = true;
            break;

          case "laundry_degreasing":
            this.priceListModalData.allowedTabs.laundry_degreasing = true;
            break;

          case "maintenance_cleaning":
            this.priceListModalData.allowedTabs.maintenance_cleaning = true;
            break;

          case "threed_ops":
            this.priceListModalData.allowedTabs.threed_ops = true;
            break;

          case "personal_transport":
            this.priceListModalData.allowedTabs.personal_transport = true;
            break;

          case "goods_transport":
            this.priceListModalData.allowedTabs.goods_transport = true;
            break;

          default:
            break;
        }
      });
    },
    useLifebase(id) {
      this.lifebases.forEach((element) => {
        if (element.id == id) {
          if (element.toBeUsed) {
            element.toBeUsed = false;
          } else {
            element.toBeUsed = true;
          }
        }
      });
    },
    _handleNotificationsArea() {
      if (this.contractualScope.length > 0) {
        this.showAlertsInputs = true;
      } else {
        this.showAlertsInputs = false;
      }
    },

    showPrestationsModalClick(base) {
      this.priceListModalData.base = base;
      this.priceListModalData.contract = this.contract;
      this.showLifebaseServicesPricingModal = true;
    },
  },

  data() {
    return {
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "DA ",
        suffix: " ",
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
      priceListModalData: {
        base: null,
        contract: null,
        allowedTabs: {
          hosting: false,
          catering: false,
          maintenance_of_facilities: false,
          laundry_degreasing: false,
          maintenance_cleaning: false,
          threed_ops: false,
          personal_transport: false,
          goods_transport: false,
        },
      },
      priceListModalCampName: "",
      showDeleteButtonOnServiceModal: false,
      showAlertsInputs: false,
      showLifebaseServicesPricingModal: false,
      directives: { money: VMoney },
      title: "Contrats",
      items: [
        {
          text: "Contrats",
          href: "javascript:void(0)",
        },
        {
          text: "Contrats",
          active: true,
        },
      ],

      services: [
        {
          id: "1",
          title: "Restauration",
          amount: 0,
          show: 0,
        },
        {
          id: "2",
          title: "Hébergement",
          amount: 0,
          show: 0,
        },
        {
          id: "3",
          title: "Maintenance des Installations",
          amount: 0,
          show: 0,
        },
        {
          id: "4",
          title: "Blanchisserie & Dégraissage",
          amount: 0,
          show: 0,
        },
        {
          id: "5",
          title: "Entretien & Nettoyage",
          amount: 0,
          show: 0,
        },
        {
          id: "6",
          title: "Espaces Verts",
          amount: 0,
          show: 0,
        },
        {
          id: "7",
          title: "Opérations 3D",
          amount: 0,
          show: 0,
        },
        {
          id: "8",
          title: "Transport du Personnel",
          amount: 0,
          show: 0,
        },
        {
          id: "9",
          title: "Transport de Marchandise",
          amount: 0,
          show: 0,
        },
        {
          id: "10",
          title: "Autres Prestations & Fournitures diverses",
          show: 0,
        },
      ],

      lifebases: [],
      displayedFields: {},
      contractForm: {
        contractRef: "",
        contractSupplier: "",
        contractType: "",
        contractPeriodFrom: "",
        contractPeriodTo: "",
        contractAmnt: "",
        contractComments: "",
        // catering contract
        contractAllocatedHostingAmmount: "",
        contractAllocatedCateringAmmount: "",
        contractAllocated3dAmmount: "",
        // gardenig contract
        contractPesticidesAmountAllocated: "",
        contractConstructionsAmountAllocated: "",
        contractRollingEquipmentsAmountAllocated: "",
        contractMaintenanceAmountAllocated: "",
        // other contract
        contractSignatureDate: "",
        contractEffectiveDate: "",
      },
      types: [
        {
          id: "catering",
          name: "Full Catering",
        },

        {
          id: "Maintenance",
          name: "Maintenance",
        },

        {
          id: "gardening",
          name: "Espace Vert",
        },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
    };
  },

  validations: {
    contractForm: {
      contractRef: {
        required,
        maxLength: maxLength(64),
      },
      contractSupplier: { required },
      contractType: { required },
      contractPeriodFrom: { required },
      contractPeriodTo: { required },
      contractAmnt: { required, decimal },
      contractAllocatedHostingAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering";
        }),
      },
      contractAllocatedCateringAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering";
        }),
      },
      contractAllocated3dAmmount: {
        required: requiredIf(function() {
          return this.contractForm.contractType == "catering";
        }),
      },
    },
  },
};
</script>
