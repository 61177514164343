<template>
  <div>
    <v-table :data="synoptics" class="table">
      <thead slot="head">
        <th>Type paragraphe</th>
        <th>Num Article</th>
        <th>Désignation</th>
        <th>Description Contenu</th>
        <th>Num Page</th>
        <th>Actions</th>
      </thead>
      <tbody slot="body" slot-scope="{ displayData }">
        <tr v-for="row in displayData" :key="row.id">
          <td>{{ row.paragraph.designation }}</td>
          <td>{{ row.article_no }}</td>
          <td>{{ row.name }}</td>
          <td><span v-html="row.content"></span></td>
          <td>{{ row.page_no }}</td>
          <td>
              <a href="javascript:void(0)" @click="editSingleSynoptic(row.id)"><i class="fas fa-edit"></i></a>
              <a href="javascript:void(0)" @click="deleteSynoptic(row.name, row.id)"><i class="fas fa-trash text-danger ml-2"></i></a>
          </td>
        </tr>
      </tbody>
    </v-table>
    <div class="row">
      <div class="col-md-12">
        <h5>
          <a @click.prevent="addNewContractSynpticClick"
            href="javascript:void(0)"
            class=""
            ><i class="fas fa-plus-circle"></i> Ajouter</a
          >
        </h5>
      </div>
    </div>

    <Modal
      v-model="showNewContractSynpticModal"
      title="Ajouter une synoptique"
    >
      <form @submit.prevent="saveNewSynoptic">
        
        <div class="row">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group">
                  <label for="formrow-email-input">Type *</label>
                  <multiselect
                    :searchable="true"
                    v-model="newSynopticForm.type"
                    track-by="id"
                    label="designation"
                    :options="synopticsTypes"
                    placeholder="Select Type"
                    :select-label="''"
                    :allow-empty="false"
                    :class="{
                      'is-invalid':
                        submitted &&
                        $v.newSynopticForm.type.$error,
                    }"
                    
                  >
                    <template slot="singleType" slot-scope="{ type }">{{
                      type.designation
                    }}</template>
                  </multiselect>
                  <div v-if="submitted && $v.newSynopticForm.type.$error" class="invalid-feedback">
                    <span v-if="!$v.newSynopticForm.type.required">Champ Obligatoire.</span>
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Article N° *</label>
                  <input 
                  type="text" 
                  v-model="newSynopticForm.articleNo" 
                  :class="{
                      'is-invalid':
                        submitted &&
                        $v.newSynopticForm.articleNo.$error,
                    }"
                  class="form-control" 
                  name="" id="">
                  <div v-if="submitted && $v.newSynopticForm.articleNo.$error" class="invalid-feedback">
                    <span v-if="!$v.newSynopticForm.articleNo.required">Champ Obligatoire.</span>
                    <span v-if="!$v.newSynopticForm.articleNo.maxLength">Le Champ ne doit pas dépasser <b>5 Charactères</b> .</span>
                  </div>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group">
                  <label for="formrow-email-input">Désignation *</label>
                  <input type="text" 
                  v-model="newSynopticForm.name" 
                  :class="{
                      'is-invalid':
                        submitted &&
                        $v.newSynopticForm.name.$error,
                    }"
                  class="form-control" 
                  name="" id="">
                  <div v-if="submitted && $v.newSynopticForm.name.$error" class="invalid-feedback">
                    <span v-if="!$v.newSynopticForm.name.required">Champ Obligatoire.</span>
                    <span v-if="!$v.newSynopticForm.name.maxLength">Le Champ ne doit pas dépasser <b>255 Charactères</b> .</span>
                  </div>
                </div>
              </div>
                <div class="col-md-2">
                <div class="form-group">
                  <label for="formrow-email-input">Page N° *</label>
                  <input 
                  type="text" 
                  v-model="newSynopticForm.pageNo" 
                  :class="{
                      'is-invalid':
                        submitted &&
                        $v.newSynopticForm.pageNo.$error,
                    }"
                  class="form-control" name="" id="">
                  <div v-if="submitted && $v.newSynopticForm.pageNo.$error" class="invalid-feedback">
                    <span v-if="!$v.newSynopticForm.pageNo.required">Champ Obligatoire.</span>
                    <span v-if="!$v.newSynopticForm.pageNo.maxLength">Le Champ ne doit pas dépasser <b>5 Charactères</b> .</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                <div class="form-group">
                  <label for="formrow-email-input">Description contenu *</label>
                  <ckeditor
                    :class="{
                      'is-invalid':
                          submitted && $v.newSynopticForm.content.$error,
                      }"
                    v-model="newSynopticForm.content"
                    :editor="editor"
                  ></ckeditor>
                  <div v-if="submitted && $v.newSynopticForm.content.$error" class="invalid-feedback">
                    <span v-if="!$v.newSynopticForm.content.required">Champ Obligatoire.</span>
                  </div>
                </div>
              </div>
            </div>

            
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right" type="submit">
              <i class="fas fa-save"></i>
              Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>
<script>
import {
  required,
  maxLength
} from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import { getDropDownElementsByTypeApi } from "@/api/common";
import Swal from "sweetalert2";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default {
  components: { Multiselect, ckeditor: CKEditor.component },
  import: {getDropDownElementsByTypeApi},
  props: {
    contract: {},
    shouldClearAllForms: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    newSynopticForm: {
      type: {
        required,
      },
      articleNo: {
        required,
        maxLength: maxLength(5),
      },
      name: {
        required,
        maxLength: maxLength(255),
      },

      pageNo: {
        required,
        maxLength: maxLength(5),
      },

      content: {
        required,
      },
      
    },
  },
  data() {
    return {
      synoptics: [],
      synopticsTypes: [],
      editor: ClassicEditor,
      editorData: "<h3>Hello World!</h3><h5><b>This is an simple editable area.</b></h5>",
      content: "<h1>Some initial content</h1>",
      submitted: false,
      newSynopticForm: {
        type: "",
        articleNo: "",
        name: "",
        pageNo: "",
        content: "",
      },
      showNewContractSynpticModal: false,
    };
  },

  watch: {
    contractualScope() {
      this.contractualScope.forEach((element) => {
        element.enteredAmount = 0;
      });
    },

    shouldClearAllForms(){
      if(this.shouldClearAllForms){
        this.clearForm();
      }
    },

    contract(){
      if(this.contract){
        this.reloadSynopticsTable();    
      }else{
        this.clearForm();
      }
    },
  },

  mounted() {
    this.fetchContractSynpticTypes();
  },

  methods: {
    clearForm(){
        this.synoptics = [];
    },
    saveNewSynoptic(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
        if(!this.$v.$invalid){
          let loader = this.$loading.show({
          container: this.$refs.finacialInfosContainer,
        });
        this.$http
          .post(
            "/contracts/contracts/saveSynopticData",
            {
              contractId: this.contract.id,
              newSynopticForm: this.newSynopticForm,
            }
          )

          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var contract = res.data.original.saveSynopticData;
            switch (status) {
              case 200:
                this.$toast.success(res.data.original.msg);
                this._reset_new_synoptic_modal();
                this.$emit("reloadSynopticsTable", true);
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
        }
        
      // if (!this.$v.$invalid) {
      // }
    },
    _reset_new_synoptic_modal(){
      this.showNewContractSynpticModal = false;
      this.submitted = false;
      this.newSynopticForm.type = "";
      this.newSynopticForm.articleNo = "";
      this.newSynopticForm.name = "";
      this.newSynopticForm.pageNo = "";
      this.newSynopticForm.content = "";
    },
    reloadSynopticsTable(){
      this.synoptics = this.contract.synoptics;
    },
    
    addNewContractSynpticClick() {
      this.showNewContractSynpticModal = true;
    },

    fetchContractSynpticTypes(){
      getDropDownElementsByTypeApi('contract_synoptic_type')
      .then((res) => {
          this.synopticsTypes = res.data.original.list;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },

    editSingleSynoptic(synId){
      // get service details
      var loader = this.$loading.show();
        this.$http.post("/contracts/contracts/getSingleSynopticDetails",{
          synId: synId
        })
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var synRec = res.data.original.synoptic;
            switch (status) {
              case 200:
                // this.$toast.success(res.data.original.msg);
                this.newSynopticForm.type = synRec.paragraph;
                this.newSynopticForm.articleNo = synRec.article_no;
                this.newSynopticForm.name = synRec.name;
                this.newSynopticForm.pageNo = synRec.page_no;
                this.newSynopticForm.content = synRec.content;

                this.newSynopticForm.isUpdate = true;
                this.newSynopticForm.synId = synRec.id;
        
                this.showNewContractSynpticModal = true;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },

    deleteSynoptic(synName, synId){
      Swal.fire({
        title: "Êtes-vous sûr de supprimer L'element: '" + synName + "' ?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/contracts/contracts/deleteContractSynoptic", {
              synId: synId,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.original.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.original.msg);
                  this.$emit("reloadSynopticsTable", true);
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    }
  },
};
</script>