<template>
  <div>
    <b-alert show variant="info">
      <div class="row">
        <div class="col-md-4">
          <h5>Montant Alloué</h5>
          {{ formatAmount(allocatedAmnt) }}
        </div>

        <div class="col-md-4">
          <h5>Montant Présenté</h5>
          {{ formatAmount(sumItems()) }}
        </div>

        <div class="col-md-4" :class="difference() != 0 ? 'text-danger' : 'text-success'">
          <h5>Ecart</h5>
          {{ formatAmount(difference()) }}
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
export default {
  props: {
    allocatedAmnt: {},
    items: {},
  },
  methods: {
    formatAmount(val, currency = "DZD") {
      return new Intl.NumberFormat("de-DE", {
        style: "currency",
        currency: currency,
      }).format(val);
    },

    sumItems(){
        var total = 0;
        this.items.forEach(element => {
            var unitPrice = parseFloat(element.unit_price.plain);
            var ctrQty = parseFloat(element.contractual_qty.plain);
            var subTotal = unitPrice * ctrQty;
            total += subTotal;
        });
        return total;
    },

    difference(){
        return this.allocatedAmnt - this.sumItems();
    }
  },

  data() {
    return {
      enteredAmnt: 0,
    //   difference: 0,
    };
  },
};
</script>
